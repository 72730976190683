import { jsx as _jsx } from "react/jsx-runtime";
import { useLayoutEffect, useState } from 'react';
import { useDocumentVisibility } from '~/common/hooks';
import { cx } from '~/common/utils';
import { Tooltip } from '../Tooltip';
import { mountCompareContainer, transferNecessaryStyles, unmountCompareContainer, } from './compareContainer';
// TODO
// Add an ability to open tooltip on keyboard navigation
// Note - I've tried setting tooltip props on focus, but had no luck
/**
 * Mount it anywhere globally to have a tooltip on any element that has data-tt attribute or truncate class
 * You can use data-tt-placement, data-tt-class, data-tt-color attributes on the element
 * like on normal tooltip
 */
const getCommonTooltipProps = (element) => {
    const closestLink = element === null || element === void 0 ? void 0 : element.closest('a');
    // There are cases with links that have truncated text which is shown in the tooltip as truncated too
    // We need to show full url in the tooltip in this case
    const closestHref = (closestLink === null || closestLink === void 0 ? void 0 : closestLink.href) && decodeURI(closestLink.href);
    const content = (element === null || element === void 0 ? void 0 : element.getAttribute('data-tt')) || closestHref || (element === null || element === void 0 ? void 0 : element.textContent);
    return {
        color: (element === null || element === void 0 ? void 0 : element.getAttribute('data-tt-color')) || 'white',
        className: cx(element === null || element === void 0 ? void 0 : element.getAttribute('data-tt-class'), 'max-w-[230px]', closestLink && 'break-all'),
        content,
        placement: (element === null || element === void 0 ? void 0 : element.getAttribute('data-tt-placement')) || 'top',
        reference: element || null,
    };
};
export const GlobalTooltip = () => {
    const documentVisible = useDocumentVisibility();
    const [tooltipProps, setTooltipProps] = useState(getCommonTooltipProps());
    useLayoutEffect(() => {
        const compareContainer = mountCompareContainer();
        const tooltipsObserver = new MutationObserver(() => {
            setTooltipProps((props) => { var _a; return (((_a = props.reference) === null || _a === void 0 ? void 0 : _a.isConnected) ? props : getCommonTooltipProps()); });
        });
        tooltipsObserver.observe(document.body, {
            childList: true,
            subtree: true,
        });
        const enterEventHandler = ({ target }) => {
            var _a;
            if (!(target instanceof Element))
                return;
            const ttTarget = target === null || target === void 0 ? void 0 : target.closest('[data-tt]');
            if (ttTarget && ((_a = ttTarget.getAttribute('data-tt')) === null || _a === void 0 ? void 0 : _a.length)) {
                return setTooltipProps(getCommonTooltipProps(ttTarget));
            }
            if (compareContainer.innerHTML) {
                compareContainer.innerHTML = '';
            }
            const truncateTarget = target === null || target === void 0 ? void 0 : target.closest('.truncate');
            if (truncateTarget) {
                compareContainer.innerText = truncateTarget.textContent || '';
                transferNecessaryStyles(truncateTarget, compareContainer);
                if (truncateTarget.clientWidth < compareContainer.clientWidth) {
                    setTooltipProps(getCommonTooltipProps(truncateTarget));
                }
            }
        };
        document.addEventListener('pointerover', enterEventHandler, true);
        return () => {
            tooltipsObserver.disconnect();
            unmountCompareContainer();
            document.removeEventListener('pointerover', enterEventHandler);
        };
    }, []);
    useLayoutEffect(() => {
        if (!documentVisible) {
            setTooltipProps(getCommonTooltipProps());
        }
    }, [documentVisible]);
    return _jsx(Tooltip, { ...tooltipProps });
};
