import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useForm } from 'formoid';
import { useState } from 'react';
import { Badge, Button, Popover } from '~/common/components';
import { MultiselectList } from '~/common/kits/table';
import { useUpdateTags } from '~/common/kits/tags';
import { cx } from '~/common/utils';
import { useCustomerData, useInitData, useJoinedCustomerData } from '~/customers/hooks';
import { useCurrentUserData } from '~/root';
export const CustomerTags = () => {
    const popoverState = useState(false);
    const { permissions } = useCurrentUserData();
    const { customer_account_id, tags } = useCustomerData();
    const { appliedCustomCustomerTagsList } = useJoinedCustomerData();
    const { taggable_type, filter_options } = useInitData();
    const updateTagsMutation = useUpdateTags({
        taggable_id: customer_account_id,
        taggable_type,
    });
    const { values, setValues, handleSubmit, isSubmitting, handleReset } = useForm({
        initialValues: { tags: appliedCustomCustomerTagsList },
        validationStrategy: 'onBlur',
        validators: () => ({ tags: null }),
    });
    const submit = () => handleSubmit(({ tags }) => {
        return updateTagsMutation.mutateAsync(tags).then(() => popoverState[1](false));
    });
    const handleClear = () => setValues(() => ({ tags: [] }));
    if (!tags.length && !permissions.customersUpdate)
        return null;
    return (_jsxs("div", { className: "flex w-full gap-1", children: [_jsx("div", { className: "flex flex-wrap gap-1 w-full items-center", children: tags.length
                    ? tags.map(({ color, name, value, hint }) => (_jsx(Badge, { "data-tt": cx(hint), color: color, children: name }, value)))
                    : '—' }), permissions.customersUpdate && (_jsx(Popover, { externalState: popoverState, onClose: () => handleReset(() => ({ tags: appliedCustomCustomerTagsList })), placement: "top-start", className: "min-w-[275px]", compensateOffset: 4, trigger: (triggerProps) => (_jsx(Button.Icon, { ...triggerProps, icon: faPlus, color: "secondary", active: popoverState[0] })), content: () => (_jsxs("div", { className: "space-y-2", children: [_jsxs("div", { children: [_jsx("p", { className: "text-greyscale-600 font-brand-t2m mb-[12px]", children: "Add custom tag" }), _jsx("div", { children: _jsx(MultiselectList, { value: values.tags, onChange: (valuesList) => setValues(() => ({ tags: valuesList })), options: filter_options.customer_custom_tags, itemRenderer: ({ color, name, value }) => (_jsx(Badge, { color: color, children: name }, value)) }) })] }), _jsx("hr", { className: "h-[1px] w-full text-greyscale-200" }), _jsxs("div", { className: "flex justify-between items-center", children: [_jsx(Button, { size: "xs", color: "text-secondary", onClick: handleClear, children: "Clear all" }), _jsx(Button, { color: "secondary", onClick: submit, disabled: isSubmitting, loading: isSubmitting, children: "Apply" })] })] })) }))] }));
};
