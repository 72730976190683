import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { Avatar, Tooltip } from '~/common/components';
import { UserCardContent, useAddUserCardId } from '~/common/kits/usercard';
import { cx, getColorsFromNumber, getInitials } from '~/common/utils';
export const UsersGroup = ({ users, maxVisible = 3, className, size = 'small', }) => {
    const amountOfHiddenUsers = users.length - maxVisible;
    const visibleUsers = amountOfHiddenUsers > 0 ? users.slice(0, maxVisible - 1) : users;
    useAddUserCardId(...users.map((user) => user.id));
    return (_jsx(Tooltip, { className: "p-0 w-[240px]", content: _jsx("div", { className: "overflow-y-auto max-h-[50vh]", children: users.map(({ id }) => (_jsxs(Fragment, { children: [_jsx(UserCardContent, { id: id }), _jsx("hr", { className: "h-[1px] text-greyscale-300 last-of-type:hidden" })] }, id))) }), clickable: true, children: _jsxs("div", { className: cx('relative flex mr-[10px] cursor-default', className), children: [visibleUsers.map((user, index) => {
                    var _a;
                    return (_jsx(Avatar, { colorSet: getColorsFromNumber(user.id), url: (_a = user.avatar_link) !== null && _a !== void 0 ? _a : undefined, className: "mr-[-10px]", size: size, style: { zIndex: maxVisible - index }, children: getInitials(user.name) }, user.id));
                }), amountOfHiddenUsers > 0 && (_jsxs("div", { className: cx('flex items-center justify-center rounded-full bg-greyscale-50 border-solid border border-greyscale-300 text-greyscale-500 cursor-default', {
                        'h-4 w-4 font-brand-c1m': size === 'small',
                        'h-3 w-3 font-brand-c2m': size === 'xs',
                    }), children: ["+", amountOfHiddenUsers + 1] }))] }) }));
};
