import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FloatingNode, FloatingPortal, autoUpdate, offset, size, useDismiss, useFloating, useFloatingNodeId, useFocus, useInteractions, useTransitionStatus, } from '@floating-ui/react';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { MultiselectList } from '~/common/kits/table';
import { cx } from '~/common/utils';
import fade from '~/styles/fade.module.scss';
import { IconBox } from '../../../components/UI/IconContainers';
import { Input } from '../../../components/UI/Input';
import styles from './Multiselect.module.scss';
const defaultItemRenderer = (props) => {
    return _jsx("span", { className: "truncate", children: props.name });
};
export const CategoryMultiselect = ({ reset, onBlur, categories, placeholder, placement = 'bottom', className, theme = 'light', }) => {
    const [open, setOpen] = useState(false);
    const nodeId = useFloatingNodeId();
    const [inputValue, setInputValue] = useState('');
    let chosenValues = 0;
    categories.forEach(({ list }) => {
        list.forEach(({ value }) => {
            chosenValues += (value === null || value === void 0 ? void 0 : value.length) || 0;
        });
    }, 0);
    const filteredCategories = categories
        .map(({ list, label }) => {
        const filteredList = list
            .map(({ options, ...rest }) => {
            const filteredOptions = options.filter(({ name }) => name.toLowerCase().includes(inputValue.toLowerCase()));
            return filteredOptions.length
                ? {
                    options: filteredOptions,
                    ...rest,
                }
                : undefined;
        })
            .filter(Boolean);
        return filteredList.length ? { list: filteredList, label } : undefined;
    })
        .filter(Boolean);
    const { refs, floatingStyles, context } = useFloating({
        nodeId,
        placement,
        open,
        whileElementsMounted: autoUpdate,
        onOpenChange: setOpen,
        middleware: [
            size({
                apply({ elements }) {
                    var _a, _b;
                    Object.assign((_b = (_a = refs.floating.current) === null || _a === void 0 ? void 0 : _a.style) !== null && _b !== void 0 ? _b : {}, {
                        width: `${elements.reference.getBoundingClientRect().width}px`,
                    });
                },
                padding: 10,
            }),
            offset(5),
        ],
    });
    const { getReferenceProps, getFloatingProps } = useInteractions([
        useFocus(context),
        useDismiss(context),
    ]);
    const { isMounted, status } = useTransitionStatus(context);
    const handleBlur = (event) => {
        var _a;
        if (!((_a = refs.floating.current) === null || _a === void 0 ? void 0 : _a.contains(event.relatedTarget))) {
            setInputValue('');
            setOpen(false);
            onBlur === null || onBlur === void 0 ? void 0 : onBlur(event);
        }
    };
    const handleChange = (event) => {
        updateInput(event.target.value);
    };
    const updateInput = (value) => {
        setInputValue(value);
        setOpen(true);
    };
    const handleFocus = () => {
        updateInput('');
    };
    const inputFieldProps = getReferenceProps({
        ref: refs.setReference,
        value: inputValue,
        onChange: handleChange,
        onFocus: handleFocus,
        onBlur: handleBlur,
    });
    const handleToggle = () => {
        setOpen((v) => !v);
    };
    const inputMarkup = (_jsx(Input, { ...inputFieldProps, placeholder: chosenValues ? `${chosenValues} selected` : placeholder, size: "small", theme: chosenValues ? 'filter' : theme, className: className, children: chosenValues ? (_jsx(IconBox, { size: "s", className: "cursor-pointer text-greyscale-0", icon: faTimesCircle, onClick: (e) => {
                e.preventDefault();
                reset();
            } })) : (_jsx(IconBox, { size: "s", className: cx(styles.chevron, open && styles.chevronActive), icon: faChevronUp, onClick: handleToggle })) }));
    return (_jsxs(_Fragment, { children: [inputMarkup, isMounted && (_jsx(FloatingNode, { id: nodeId, children: _jsx(FloatingPortal, { children: _jsx("div", { ...getFloatingProps({
                            ref: refs.setFloating,
                            className: cx(styles.container, styles.categorizedMultiselect, fade.floating, 'overscroll-contain'),
                            style: floatingStyles,
                        }), "data-status": status, children: _jsx("div", { className: "py-[4px]", children: filteredCategories.length ? (filteredCategories.map(({ label, list }, index) => {
                                return (_jsxs("div", { children: [label && (_jsx("p", { className: "font-brand-b1 text-greyscale-500 px-1 py-[5px]", children: label })), list.map(({ options, onChange, value, itemRenderer }, index) => {
                                            if (!options.length)
                                                return;
                                            return (_jsx(MultiselectList, { className: "px-1", itemRenderer: itemRenderer || defaultItemRenderer, onChange: (value) => {
                                                    onChange(value);
                                                    setInputValue('');
                                                }, options: options, value: value }, index));
                                        })] }, label || index));
                            })) : (_jsx("div", { className: styles.notFound, children: "Nothing found:(" })) }) }) }) }))] }));
};
