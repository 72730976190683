import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { faStar, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { useMemo } from 'react';
import { Badge, IconBox, badgeIcons } from '~/common/components';
import { cx } from '~/common/utils';
const getUpdatedFilterValue = (value, newValue) => {
    if (Array.isArray(value)) {
        return value.filter((v) => v.toString() !== newValue.toString());
    }
    return undefined;
};
export const FilterBarRow = ({ title, filters, children }) => {
    const values = useMemo(() => {
        return filters.map(({ value, options, ...props }) => {
            const filteredOptions = options.filter((option) => {
                return Array.isArray(value)
                    ? value.map((value) => value.toString()).includes(option.value.toString())
                    : value === option.value;
            });
            return {
                ...props,
                options: filteredOptions,
                value,
            };
        });
    }, [filters]);
    return (_jsxs("div", { className: "flex items-center gap-1", children: [_jsxs("span", { className: "text-greyscale-400 font-brand-b2r", children: [title, ":"] }), _jsxs("div", { className: "flex gap-1 flex-wrap", children: [values === null || values === void 0 ? void 0 : values.map(({ onChange, options, value }) => options.map((val) => {
                        const icon = badgeIcons[val.name];
                        return (_jsxs(Badge.Button, { "data-tt": cx('hint' in val && val.hint), color: ('color' in val && val.color) || 'lighter-grey', onClick: () => onChange(getUpdatedFilterValue(value, val.value)), className: "flex gap-[10px] items-center", children: [icon ? (_jsx(IconBox, { icon: faStar, className: "text-inherit" })) : (_jsx("span", { children: val.name })), _jsx(IconBox, { size: "s", icon: faTimesCircle })] }, val.value));
                    })), children] })] }));
};
