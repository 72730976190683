import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faChartLineUp, faSubscript } from '@fortawesome/pro-regular-svg-icons';
import { useHistory } from 'react-router-dom';
import { BackButton, LayoutContent, LayoutHeader, LayoutTitle, Link, Menu, NotFound, Tabs, useModalContext, withModalContext, } from '~/common/components';
import { is404 } from '~/common/utils';
import { useCurrentUserData, useTimezones } from '~/root';
import { SUBSCRIPTIONS_URL, useCapacityBreakdown, useInit, usePayments, usePaymentsInit, useSubscription, useSubscriptionData, } from '../hooks';
import { tabs, useSubscriptionsContext, withSubscriptionsContext } from './context';
import { EditUploadWindow, EditDedicatedTeam, EditSuccessTeam } from './General';
export const Subscription = withSubscriptionsContext(({ children }) => {
    const init = useInit();
    const subscription = useSubscription();
    const breakdown = useCapacityBreakdown();
    const payments = usePayments();
    const paymentsInit = usePaymentsInit();
    const timezones = useTimezones();
    if (is404(subscription.error)) {
        return (_jsx(NotFound, { icon: faSubscript, text: "There's no subscription with this id", safeLocation: SUBSCRIPTIONS_URL }));
    }
    if (!subscription.data ||
        !init.data ||
        breakdown.data === undefined ||
        !payments.data ||
        !paymentsInit.data ||
        !timezones.data) {
        return _jsx(LayoutContent, { loading: true });
    }
    return _jsx(SubscriptionContent, { children: children });
});
export const SubscriptionRedirect = () => {
    const subscription = useSubscription();
    const history = useHistory();
    if (subscription.data) {
        history.replace(`${SUBSCRIPTIONS_URL}/${subscription.data.id}/customer/${subscription.data.owner_id}`);
    }
    if (is404(subscription.error)) {
        return (_jsx(NotFound, { icon: faSubscript, text: "There's no subscription with this id", safeLocation: SUBSCRIPTIONS_URL }));
    }
    return _jsx(LayoutContent, { loading: true });
};
const SubscriptionContent = withModalContext(({ children }) => {
    const { activeTab, setActiveTab } = useSubscriptionsContext();
    const { modalOpener } = useModalContext();
    const { permissions } = useCurrentUserData();
    const subscription = useSubscriptionData();
    const isMenuVisible = permissions.subscriptionsUpdate;
    return (_jsxs(LayoutContent, { children: [_jsxs("div", { className: "sticky top-0 z-10 mb-2", children: [_jsxs(LayoutHeader, { className: "flex justify-between items-center gap-2 mb-0", children: [_jsx(BackButton, { to: SUBSCRIPTIONS_URL }), _jsxs(LayoutTitle, { className: "-ml-2 mr-auto flex-none", children: [subscription.owner_name, " subscription"] }), _jsx(Link.Button, { newTab: true, href: `https://insights.24slides.dev/public/dashboard/7d9cd141-4000-47f3-9539-73b44d0865f8?owner_email=${subscription.owner_email}`, size: "small", color: "text-secondary", icon: faChartLineUp, children: "Performance" }), isMenuVisible && (_jsxs(Menu, { children: [!subscription.upload_window && (_jsx(Menu.Item, { onClick: modalOpener(EditUploadWindow), children: "Add upload window" })), _jsx(Menu.Item, { onClick: modalOpener(EditSuccessTeam), children: "Set success team" }), _jsx(Menu.Item, { onClick: modalOpener(EditDedicatedTeam), children: "Set dedicated team" })] }))] }), _jsx(Tabs, { className: "flex items-center gap-5 pt-2 bg-greyscale-75 def:font-brand-t1m leading-6 border-b-2 border-b-solid border-b-greyscale-200", tabs: tabs, active: activeTab, onChange: setActiveTab })] }), _jsx("div", { className: "h-full overflow-y-auto flex flex-col", children: children })] }));
});
