import { z } from 'zod';
import { DateFromIsoString } from '~/common/utils';
import { NumericIdNamePair, Tag, UploadedFile } from '~/root';
export const UserAvatarInfo = z.object({
    id: z.number(),
    name: z.string(),
    avatar_link: z.string().nullable(),
});
const BasicUserCard = z.object({
    id: z.number(),
    avatar: UploadedFile.nullable(),
    name: z.string(),
    job_title: z.string(),
    office: z.string(),
});
export const NoShiftUserCard = BasicUserCard;
export const CustomerSupporterCard = BasicUserCard.extend({
    on_shift: z.boolean(),
});
export const DesignManagerCard = CustomerSupporterCard.extend({
    design_team: z.string().nullable(),
});
export const DesignerCard = DesignManagerCard.extend({
    capacity: z.number(),
});
export const AnyUserCard = z.union([
    DesignerCard,
    DesignManagerCard,
    CustomerSupporterCard,
    NoShiftUserCard,
]);
export const RegularCustomerCard = z.object({
    id: z.number(),
    owner_id: z.number().nullable(),
    avatar: UploadedFile.nullable(),
    name: z.string(),
    role: z.string(),
    tags: z.array(Tag),
    company_name: z.string().nullable(),
    last_active_at: DateFromIsoString,
});
export const SubscriptionCustomerCard = RegularCustomerCard.extend({
    products: z.array(NumericIdNamePair.and(z.object({
        plan_id: z.number(),
    }))),
    capacity: z.number(),
});
export const AnyCustomerCard = z.union([SubscriptionCustomerCard, RegularCustomerCard]);
export const AnyCard = z.union([AnyUserCard, AnyCustomerCard]);
export const CardStore = (ids) => {
    const timestamp = Date.now();
    return z
        .array(AnyCard.nullable())
        .transform((cards) => new Map(cards.map((card, index) => [ids[index], { card, timestamp }])));
};
