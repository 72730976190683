import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Button } from '~/common/components';
import { getFilterProps, useParsedQuery } from '~/common/kits/table';
import { FilterBarRow } from '../components';
import { useReviewsInitData } from '../hooks';
export const ReviewsFilterBar = () => {
    const { queryParams, onFilterChange, updateFilters } = useParsedQuery();
    const { filter_options } = useReviewsInitData();
    const filterProps = getFilterProps(filter_options, queryParams.filter, onFilterChange);
    const filters = useMemo(() => {
        return ([
            {
                title: 'Type',
                name: ['customer_type'],
            },
            {
                title: 'PM',
                name: ['manager'],
            },
            {
                title: 'Design team',
                name: ['team'],
            },
            {
                title: 'Portal',
                name: ['custom_portal'],
            },
            {
                title: 'Organization',
                name: ['organisation'],
            },
            {
                title: 'Rating',
                name: ['rating'],
            },
        ]).map((item) => {
            return {
                ...item,
                filters: item.name.map((name) => filterProps(name)),
            };
        });
    }, [filterProps]);
    const appliedFilters = filters.filter((filter) => filter.name.some((name) => queryParams.filter[name]));
    const resetFilters = () => {
        updateFilters(() => ({}));
    };
    if (!appliedFilters.length) {
        return null;
    }
    return (_jsx("div", { className: "flex flex-col gap-1 mt-1", children: appliedFilters.map((props, index) => (_jsx(FilterBarRow, { ...props, children: index === appliedFilters.length - 1 && (_jsx(Button, { size: "xs", color: "text-secondary", className: "ml-2", onClick: resetFilters, children: "Clear All" })) }, props.title.toString()))) }));
};
