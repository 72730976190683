import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faBoxOpen, faGrid } from '@fortawesome/pro-duotone-svg-icons';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { Badge, Button, ChunkRenderer, IconBox, Link, Menu } from '~/common/components';
import { getFilterProps, useFrontendTable, useParsedQuery, } from '~/common/kits/table';
import { cx, nonNullable, record } from '~/common/utils';
import { ORDERS_URL } from '~/orders';
import { useInitData, useOrdersForBoardData } from '../../hooks';
import { BoardItem } from './BoardItem';
const isClosedOrCanceled = (status) => [6, 7].includes(status);
const tableConfig = {
    columns: [
        {
            name: 'Deadline',
            key: 'deadline',
            // TODO FE sorting relies on this, make it required in table config for
            // types that are not string | number | null
            getCellValue: (date) => date.getTime(),
        },
    ],
    searchable: [],
    mergeFilters: {
        applied_tags: [
            'customer_custom_tags',
            'customer_system_tags',
            'order_custom_tags',
            'order_system_tags',
        ],
    },
    sortable: ['deadline'],
};
export const BoardTable = () => {
    const init = useInitData();
    const boardOrders = useOrdersForBoardData();
    const { queryParams, onFilterChange } = useParsedQuery();
    const getMergeTargetKey = (key) => {
        if (!tableConfig.mergeFilters)
            return;
        let targetKey;
        Object.entries(tableConfig.mergeFilters).forEach(([filterKey, value]) => {
            if (value.includes(key.toString())) {
                targetKey = filterKey;
            }
        });
        return targetKey;
    };
    const populateMergedFilterEntries = () => {
        const mergedFilterEntriesObject = {};
        record
            .entries(queryParams.filter)
            .forEach(([key, values]) => {
            if (!values)
                return;
            const targetKey = getMergeTargetKey(key) || key;
            if (!mergedFilterEntriesObject[targetKey]) {
                mergedFilterEntriesObject[targetKey] = [];
            }
            const arrayValue = Array.isArray(values) ? values : [values];
            arrayValue.forEach((key) => mergedFilterEntriesObject[targetKey].push(key));
        });
        return Object.entries(mergedFilterEntriesObject).map(([key, values]) => [
            key,
            Array.from(new Set(values)),
        ]);
    };
    const view = useFrontendTable({
        data: boardOrders.items,
        limit: Infinity,
        tableConfig,
        getFilterEntries: populateMergedFilterEntries,
    });
    const filterProps = getFilterProps(init.filter_options, queryParams.filter, onFilterChange);
    const columns = init.filter_options.status
        .filter(({ value }) => {
        const status = queryParams.filter.status;
        return !status || (Array.isArray(status) && status.includes(value));
    })
        .map((status) => ({
        status,
        // TODO remove nonNullable after we fix useFrontendTable types
        items: nonNullable(view).items.filter((item) => item.status === status.value),
    }));
    const updateStatus = (status) => {
        const statusProps = filterProps('status');
        statusProps.onChange(Array.isArray(statusProps.value)
            ? statusProps.value.filter((id) => status.value !== id)
            : init.filter_options.status
                .map((status) => status.value)
                .filter((id) => status.value !== id));
    };
    return (_jsx("div", { className: "flex w-fit space-x-2 h-full", children: columns.map(({ status, items }) => {
            return (_jsxs("div", { className: "flex flex-col w-[320px] bg-greyscale-200 rounded-lg p-[12px] space-y-[12px] overflow-hidden h-fit max-h-full", children: [_jsxs("header", { className: "flex", children: [_jsx("span", { className: "font-brand-t2m text-greyscale-600", children: status.name }), _jsx(Badge, { color: "secondary", className: cx('ml-1 mr-auto', isClosedOrCanceled(status.value) && 'invisible'), children: items.length }), _jsx(Menu, { trigger: (props) => (_jsx(Button.Icon, { ...props, icon: faEllipsisVertical, color: "grey" })), children: _jsx(Menu.Item, { onClick: () => updateStatus(status), children: "Hide column" }) })] }), _jsx("div", { className: "overflow-y-auto max-h-full rounded-[4px] space-y-[12px]", children: isClosedOrCanceled(status.value) ? (_jsxs("div", { className: "flex flex-col items-center text-center text-greyscale-400 mt-2 pb-1", children: [_jsx(IconBox, { icon: faGrid, className: "h-5 w-5 text-[36px] mb-[4px]" }), _jsx("div", { className: "font-brand-t4m text-greyscale-500 mb-[4px]", children: "Closed orders currently offstage" }), _jsx("div", { className: "font-brand-b2r mb-[12px]", children: "In order to provide a good experience, we\u00A0had to prevent loading closed orders in\u00A0Kanban view. In a meantime, you can explore those orders in the list view." }), _jsx(Link.Button, { to: `${ORDERS_URL}?filter[status]=[${status.value}]`, size: "xs", color: "text-secondary", children: "Go to list view" })] })) : !items.length ? (_jsxs("div", { className: "flex flex-col items-center text-center text-greyscale-400 mt-[12px] pb-[4px]", children: [_jsx(IconBox, { icon: faBoxOpen, className: "h-5 w-5 text-[28px] mb-1" }), _jsx("div", { className: "font-brand-t4m text-greyscale-500 mb-[4px]", children: "No orders yet" })] })) : (_jsx(ChunkRenderer, { component: BoardItem, propsList: items.map((item) => ({ ...item, key: item.id })) })) })] }, status.value));
        }) }));
};
