import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faTag } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useForm } from 'formoid';
import { useState } from 'react';
import { Badge, Button, Popover } from '~/common/components';
import { useIdParam } from '~/common/hooks';
import { MultiselectList } from '~/common/kits/table';
import { useUpdateTags } from '~/common/kits/tags';
import { cx } from '~/common/utils';
import { useInitData, useJoinedOrderData } from '~/orders/hooks';
import { useCurrentUserData } from '~/root';
import { InfoBarItem } from './InfoBarItem';
export const OrderTags = () => {
    const popoverState = useState(false);
    const { permissions } = useCurrentUserData();
    const { taggable_type, filter_options } = useInitData();
    const { appliedCustomOrderTagsList, tags } = useJoinedOrderData();
    const id = useIdParam();
    const { values, setValues, handleSubmit, isSubmitting, handleReset } = useForm({
        initialValues: { tags: appliedCustomOrderTagsList },
        validationStrategy: 'onBlur',
        validators: () => ({ tags: null }),
    });
    const updateTagsMutation = useUpdateTags({
        taggable_id: id,
        taggable_type,
    });
    const submit = () => handleSubmit(({ tags }) => {
        return updateTagsMutation.mutateAsync(tags).then(() => popoverState[1](false));
    });
    const handleClear = () => setValues(() => ({ tags: [] }));
    if (!tags.length && !permissions.ordersUpdate)
        return null;
    return (_jsxs(InfoBarItem, { icon: faTag, title: "Related tags", children: [!!tags.length && (_jsx("div", { className: "flex flex-wrap gap-1 mt-1 items-center", children: tags.map(({ color, name, value, hint }) => (_jsx(Badge, { "data-tt": cx(hint), color: color, children: name }, value))) })), permissions.ordersUpdate && (_jsx(Popover, { externalState: popoverState, onClose: () => handleReset(() => ({ tags: appliedCustomOrderTagsList })), placement: "top-start", className: "min-w-[275px]", trigger: (triggerProps) => (_jsx(Button.Icon, { ...triggerProps, icon: faPlus, color: "secondary", active: popoverState[0], className: "absolute top-1 right-1" })), content: () => (_jsxs("div", { className: "space-y-2", children: [_jsxs("div", { children: [_jsx("p", { className: "text-greyscale-600 font-brand-t2m mb-[12px]", children: "Add custom tag" }), _jsx("div", { children: _jsx(MultiselectList, { value: values.tags, className: "max-h-[192px] overflow-auto", onChange: (valuesList) => setValues(() => ({ tags: valuesList })), options: filter_options.order_custom_tags, itemRenderer: ({ color, name, value }) => (_jsx(Badge, { color: color, children: name }, value)) }) })] }), _jsx("hr", { className: "h-[1px] w-full text-greyscale-200" }), _jsxs("div", { className: "flex justify-between items-center", children: [_jsx(Button, { size: "xs", color: "text-secondary", onClick: handleClear, children: "Clear all" }), _jsx(Button, { color: "secondary", onClick: submit, disabled: isSubmitting, loading: isSubmitting, children: "Apply" })] })] })) }))] }));
};
