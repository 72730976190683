import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faUserTag } from '@fortawesome/pro-regular-svg-icons';
import { BackButton, Banner, Button, LayoutContent, LayoutHeader, LayoutTitle, Menu, NotFound, Tabs, useModalContext, withModalContext, } from '~/common/components';
import { is404 } from '~/common/utils';
import { useCountries, useCurrentUserData, useTimezones } from '~/root';
import { CUSTOMERS_URL, useCustomer, useCustomerData, useInit, useStats } from '../hooks';
import { tabs, useCustomerContext, withCustomerContext } from './context';
import { DeleteCustomer } from './Delete';
import { EditDedicatedTeam, EditIndividualPricing, EditSuccessTeam } from './General';
import { LoginAsCustomer } from './LoginAsCustomer';
import { Suspend, Unsuspend } from './Suspension';
export const Customer = withCustomerContext(({ children }) => {
    const init = useInit();
    const customer = useCustomer();
    const countries = useCountries();
    const timezones = useTimezones();
    const stats = useStats();
    if (is404(customer.error)) {
        return (_jsx(NotFound, { icon: faUserTag, text: "There's no customer with this id", safeLocation: CUSTOMERS_URL }));
    }
    if (!customer.data || !init.data || !countries.data || !stats.data || !timezones.data) {
        return _jsx(LayoutContent, { loading: true });
    }
    return _jsx(CustomerContent, { children: children });
});
const CustomerContent = withModalContext(({ children }) => {
    const { permissions } = useCurrentUserData();
    const { activeTab, setActiveTab } = useCustomerContext();
    const customer = useCustomerData();
    const allowedTabs = permissions.customersTransactionsView
        ? tabs
        : tabs.filter((tab) => tab.key !== 'transactions');
    const isMenuVisible = permissions.customersDelete ||
        permissions.customersUpdate ||
        permissions.customersCanLoginAsCustomer;
    return (_jsxs(LayoutContent, { children: [_jsxs("div", { className: "sticky top-0 z-10 mb-2", children: [_jsxs(LayoutHeader, { className: "flex justify-between items-center gap-2 mb-0", children: [_jsx(BackButton, { to: CUSTOMERS_URL }), _jsx(LayoutTitle, { className: "-ml-2 mr-auto flex-none", children: customer.name }), isMenuVisible && _jsx(HeaderMenu, {})] }), _jsx(Tabs, { className: "flex items-center gap-5 pt-2 bg-greyscale-75 def:font-brand-t1m leading-6 border-b-2 border-b-solid border-b-greyscale-200", tabs: allowedTabs, active: activeTab, onChange: setActiveTab })] }), customer.suspension_reason && _jsx(SuspensionBanner, {}), _jsx("div", { className: "h-full overflow-auto flex flex-col", children: children })] }));
});
const SuspensionBanner = () => {
    const customer = useCustomerData();
    const { modalOpener } = useModalContext();
    return (_jsxs(Banner, { className: "flex items-center justify-between h-4 mb-2", color: "error", children: [_jsx("span", { children: customer.suspension_reason }), _jsx(Button, { onClick: modalOpener(Unsuspend), color: "text-grey", size: "xs", children: "Unsuspend" })] }));
};
const HeaderMenu = () => {
    const customer = useCustomerData();
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    return (_jsxs(Menu, { children: [permissions.customersCanLoginAsCustomer && (_jsx(Menu.Item, { onClick: modalOpener(LoginAsCustomer, { id: customer.id }), children: "Login as the customer" })), permissions.customersUpdate && (_jsx(Menu.Item, { onClick: modalOpener(EditIndividualPricing), children: "Set individual pricing" })), permissions.customersUpdate && (_jsx(Menu.Item, { onClick: modalOpener(EditSuccessTeam), children: "Set success team" })), permissions.customersUpdate && (_jsx(Menu.Item, { onClick: modalOpener(EditDedicatedTeam), children: "Set dedicated team" })), permissions.customersUpdate &&
                (customer.is_suspended ? (_jsx(Menu.Item, { onClick: modalOpener(Unsuspend), children: "Unsuspend" })) : (_jsx(Menu.Item, { onClick: modalOpener(Suspend), children: "Suspend" }))), permissions.customersDelete && (_jsx(Menu.Item, { onClick: modalOpener(DeleteCustomer), children: "Delete the customer" }))] }));
};
