import { addDays, addYears, differenceInDays, format, isWeekend } from 'date-fns';
import { z } from 'zod';
import { TimelessDateFromIsoString, formatMonthDay, getDateWithoutTime, record, nonNullable, set, range, } from '~/common/utils';
import { NumericIdNamePair, Shift, Tag, UploadedFile } from '~/root';
const DateMinMaxWithFallback = (forward = false) => {
    return TimelessDateFromIsoString.catch(addYears(getDateWithoutTime(), forward ? 1 : -1));
};
export const Init = z
    .object({
    teams: z.array(z.object({
        id: z.number().int().positive(),
        name: z.string(),
        designers: z.array(z.object({
            id: z.number().int().positive(),
            name: z.string(),
            points: z.number().int().nonnegative(),
        })),
    })),
    shifts: z.array(Shift),
    offices: z.array(NumericIdNamePair),
    min_date: DateMinMaxWithFallback(),
    max_date: DateMinMaxWithFallback(true),
    capacity_change_reasons: z.array(z.string()),
})
    .transform((init) => {
    const result = {
        min_date: init.min_date,
        max_date: init.max_date,
        reasons: init.capacity_change_reasons.map((reason) => ({ name: reason, value: reason })),
        teams_header_options: [],
        teams_popup_options: [],
        designers_popup_options: {},
        designers_points: {},
        shifts: init.shifts,
        offices: init.offices,
    };
    init.teams.forEach(({ id, name, designers }) => {
        const option = { name, value: id };
        result.designers_popup_options[id] = designers.map(({ id, name, points }) => {
            result.designers_points[id] = points;
            return { name, value: id };
        });
        result.teams_header_options.push(option);
        result.teams_popup_options.push(option);
    });
    result.teams_header_options.unshift({ name: 'All teams', value: null });
    return result;
});
export const DailyCapacity = z.object({
    plan: z.number(),
    actual: z.number(),
    is_holiday: z.boolean(),
    is_weekend: z.boolean(),
    shift_id: z.number().int().nonnegative().nullable(),
});
const IdName = z.object({
    id: z.number().int().positive(),
    name: z.string(),
});
const IdNamePair = z.object({
    id: z.number().int().positive(),
    name: z.string(),
});
const OrderContributor = IdNamePair.extend({ is_tagged: z.boolean() });
export const CapacityTotals = z
    .object({
    capacity_totals: z.object({
        total: z.number().int().nonnegative(),
        left: z.number().int().nonnegative(),
        over: z.number().int().nonnegative(),
    }),
    orders: z.array(z.object({
        capacity_total: z.number().int().nonnegative(),
        capacity_used: z.number().int().nonnegative(),
        contributors: z.array(OrderContributor),
        id: z.number().int().positive(),
        is_subscription: z.boolean(),
        status: IdName,
    })),
    capacity_changes: z.array(z.object({
        actor_name: z.string().nullable(),
        comment: z.string().nullable(),
        date: TimelessDateFromIsoString,
        designer: IdNamePair,
        id: z.number().int().positive(),
        points_from: z.number().int().nonnegative(),
        points_to: z.number().int().nonnegative(),
        reason: z.string(),
        team: IdNamePair,
    })),
})
    .transform((totals) => ({
    ...totals,
    orders: totals.orders.map((order) => ({
        ...order,
        status: order.status.name,
    })),
}));
export const TeamBreakdownDesigner = z.object({
    id: z.number().int().positive(),
    name: z.string(),
    days: z.array(DailyCapacity),
    avatar: UploadedFile.nullable(),
});
export const TeamBreakdown = ({ start, end, shifts, offices, }) => z
    .object({
    teams: z.array(z.object({
        id: z.number().int().positive(),
        name: z.string(),
        leader_id: z.number().nullable(),
        designers: z.array(TeamBreakdownDesigner),
        office_id: z.number().int().positive(),
    })),
})
    .transform(({ teams }) => {
    const avaliableShiftIds = [];
    const availableOffices = new Set();
    return {
        teams: teams.map((team) => {
            availableOffices.add(team.office_id);
            let teamDays = [];
            let teamDaysByShiftId = [];
            for (const designer of team.designers) {
                if (!teamDays.length) {
                    teamDays = designer.days.map((day) => ({ ...day, is_holiday: true }));
                    teamDaysByShiftId = designer.days.map((day, index) => {
                        if (!day.shift_id) {
                            return {};
                        }
                        if (!avaliableShiftIds[index])
                            avaliableShiftIds[index] = new Set();
                        avaliableShiftIds[index].add(day.shift_id);
                        return {
                            [day.shift_id]: day.plan - day.actual,
                        };
                    });
                }
                else {
                    designer.days.forEach((designerDay, index) => {
                        const teamDay = teamDays[index];
                        teamDay.plan += designerDay.plan;
                        teamDay.actual += designerDay.actual;
                        if (!designerDay.is_holiday) {
                            teamDay.is_holiday = false;
                        }
                        if (!designerDay.shift_id) {
                            return;
                        }
                        if (!avaliableShiftIds[index])
                            avaliableShiftIds[index] = new Set();
                        avaliableShiftIds[index].add(designerDay.shift_id);
                        const teamDayByShiftId = teamDaysByShiftId[index];
                        const remainingCapacity = designerDay.plan - designerDay.actual;
                        if (!teamDayByShiftId[designerDay.shift_id]) {
                            teamDayByShiftId[designerDay.shift_id] = remainingCapacity;
                        }
                        else {
                            teamDayByShiftId[designerDay.shift_id] += remainingCapacity;
                        }
                    });
                }
            }
            return {
                ...team,
                days: teamDays,
                daysByShiftId: teamDaysByShiftId.map((day) => record.entries(day).map(([shift_id, capacity]) => {
                    const shift = nonNullable(shifts.find((s) => s.id === +shift_id));
                    return { ...shift, capacity };
                })),
            };
        }),
        globalAvailableShifts: [...set.combine(avaliableShiftIds)].map((id) => {
            return nonNullable(shifts.find((s) => s.id === id));
        }),
        days: range(0, differenceInDays(end, start) - 1).map((dayNr, index) => ({
            date: format(addDays(start, dayNr), 'iii d').toLowerCase(),
            isWeekend: isWeekend(addDays(start, dayNr)),
            availableShifts: [...(avaliableShiftIds[index] || [])].map((id) => {
                return shifts.find((s) => s.id === id);
            }),
        })),
        availableOffices: [...availableOffices].map((id) => {
            return nonNullable(offices.find((o) => o.value === id));
        }),
    };
});
const UtilizationEntry = z.object({
    subscription_orders: z.number(),
    regular_orders: z.number(),
    over_capacity: z.number(),
    day_capacity: z.number(),
    day_capacity_left: z.number(),
});
export const OneTeamUtilization = z.array(z.union([UtilizationEntry, z.literal('weekend').transform(() => null)]));
export const AllTeamsUtilization = z.array(UtilizationEntry.extend({
    team: z.string(),
}));
export const TeamUtilization = z.union([AllTeamsUtilization, OneTeamUtilization]);
export const OrderOverview = z
    .object({
    tags: z.array(Tag),
    status: IdName,
    capacity_totals: z.object({
        order_capacity: z.number(),
        extra_capacity: z.number(),
        total: z.number(),
    }),
    slides_per_treatment: z.array(z.object({
        name: z.string(),
        slides: z.number(),
    })),
    team_breakdown: z.array(z.object({
        id: z.number(),
        name: z.string(),
        points: z.number(),
        designers: z.array(z.object({
            id: z.number(),
            name: z.string(),
            points: z.array(z.number()),
        })),
        tagTeam: z.boolean(),
    })),
    team_breakdown_dates: z.array(TimelessDateFromIsoString),
})
    .transform((overview) => ({
    ...overview,
    team_breakdown: overview.team_breakdown.map((team) => ({
        ...team,
        pointsData: team.designers.reduce((pointsData, designer) => {
            if (!pointsData.length) {
                pointsData = [...designer.points];
            }
            else {
                designer.points.forEach((points, index) => {
                    pointsData[index] += points;
                });
            }
            return pointsData;
        }, []),
        designers: team.designers.map(({ points, ...designer }) => ({
            ...designer,
            points: points.reduce((acc, points) => acc + points, 0),
            pointsData: [...points],
        })),
    })),
    team_breakdown_dates: overview.team_breakdown_dates.map(formatMonthDay),
    slides_per_treatment_total: overview.slides_per_treatment.reduce((acc, v) => acc + v.slides, 0),
}));
