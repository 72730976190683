import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { cx } from '~/common/utils';
import { TESTING } from '~/env';
import { IconBox } from '../IconContainers';
import { ThreeDots } from '../ThreeDots';
import css from './styles.module.scss';
export function getButtonProps(props) {
    const { size, active, color, icon, disabled, loading, children, ...restProps } = props;
    const isDisabled = disabled || loading;
    return {
        ...restProps,
        // apparently firefox only ignores onClick, while chrome ignores every
        // event, breaking tooltips on disabled elements
        // So it'll be pseudo disabled, ButtonBase will not recieve onClick, and disabled will be false
        // Button will be disabled visually
        disabled: TESTING ? isDisabled : false,
        onClick: isDisabled ? undefined : props.onClick,
        children: (_jsxs(_Fragment, { children: [icon && (_jsx(IconBox, { size: "s", className: cx(loading ? 'invisible' : 'visible', 'text-inherit'), icon: icon })), children && _jsx("span", { className: loading ? 'invisible' : 'visible', children: children }), loading && _jsx(ThreeDots, { className: css.dots, width: 24 })] })),
    };
}
export const getLinkProps = (props) => {
    const { external, icon, children, ...restProps } = props;
    return {
        ...restProps,
        children: (_jsxs(_Fragment, { children: [icon && _jsx(IconBox, { size: "s", icon: icon, className: "text-inherit" }), children && _jsx("span", { className: "truncate", children: children }), external && _jsx(IconBox, { size: "s", icon: faExternalLink, className: "text-inherit" })] })),
    };
};
export const getLinkIconProps = ({ size = 's', iconSize, newTab, color, icon, children, ...restProps }) => ({
    ...restProps,
    children: (_jsxs(_Fragment, { children: [icon && _jsx(IconBox, { className: "text-inherit", size: iconSize || size, icon: icon }), children && _jsx("span", { className: "truncate", children: children })] })),
});
export const getButtonIconProps = ({ size = 's', iconSize, color, icon, children, active, disabled, loading, ...props }) => {
    const isDisabled = disabled || loading;
    return {
        ...props,
        // apparently firefox only ignores onClick, while chrome ignores every
        // event, breaking tooltips on disabled elements
        // So it'll be pseudo disabled, ButtonBase will not recieve onClick, and disabled will be false
        // Button will be disabled visually
        disabled: TESTING ? isDisabled : false,
        onClick: isDisabled ? undefined : props.onClick,
        children: (_jsxs(_Fragment, { children: [!loading && icon && (_jsx(IconBox, { className: "text-inherit", size: iconSize || size, icon: icon })), !loading && children, loading && (_jsx(IconBox, { size: iconSize || size, className: "loader animate-spin text-inherit h-fit w-fit", icon: faSpinnerThird }))] })),
    };
};
export const getButtonStyles = ({ color = 'grey', size = 'small', }) => cx(css.button, css[size], css[color]);
export const getLinkStyles = ({ color = 'secondary', size = 's', }) => cx(css.link, css[size], css[color]);
export const getButtonStateStyles = ({ active = false, disabled = false, loading = false, }) => cx({
    [css.active]: active,
    [css.disabled]: disabled || loading,
});
export const getIconStyles = ({ color = 'grey', size = 's', }) => cx(css.icon, css[size], css[color]);
