import { useQuery } from '@tanstack/react-query';
import { getFlatQuery, useParsedQuery } from '~/common/kits/table';
import { assertQueryData, httpClient } from '~/common/utils';
import { CustomersList, Init } from '~/customers/domain';
import { qk } from '~/root/query-keys';
export const CUSTOMERS_URL = '/customers';
export const useInit = () => {
    return useQuery({
        queryKey: qk.customersInit,
        queryFn: () => httpClient.get(`/v1/staff/customers/init`, { decoder: Init }),
        staleTime: Infinity,
    });
};
export const useInitData = assertQueryData(useInit);
export function useCustomersList() {
    const { queryParams } = useParsedQuery();
    const params = getFlatQuery(queryParams);
    return useQuery({
        queryKey: [...qk.customersList, params],
        queryFn: () => httpClient.get('/v1/staff/customers', { decoder: CustomersList, params }),
        keepPreviousData: true,
    });
}
export const mergedTagFilters = [
    'customer_custom_tags',
    'customer_system_tags',
];
