import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FloatingNode, FloatingPortal, autoUpdate, offset, size, useDismiss, useFloating, useFloatingNodeId, useFocus, useInteractions, useTransitionStatus, } from '@floating-ui/react';
import { faChevronUp, faSquare } from '@fortawesome/pro-regular-svg-icons';
import { faCheckSquare, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { useMemo, useState } from 'react';
import { cx } from '~/common/utils';
import fade from '~/styles/fade.module.scss';
import { IconBox } from '../../../components/UI/IconContainers';
import { Input } from '../../../components/UI/Input';
import styles from './Multiselect.module.scss';
const getUpdatedFilterValue = (value, newValue, isIncluded) => {
    if (!value)
        return [newValue.toString()];
    return isIncluded
        ? value.filter((v) => v.toString() !== newValue.toString())
        : [...value, newValue.toString()];
};
export const MultiselectList = ({ options, value, onChange, itemRenderer = ({ name }) => _jsx("span", { className: "truncate", children: name }), className, }) => {
    return (_jsx("ul", { className: cx('flex flex-col', className), children: options.length ? (options.map((option) => {
            const isActive = !!value && value.some((id) => id.toString() === option.value.toString());
            return (_jsxs("li", { className: cx('px-[4px] py-[4px] flex items-center gap-[4px] font-brand-b1 cursor-pointer hover:bg-greyscale-100 rounded', isActive ? 'text-secondary-400' : 'text-greyscale-500'), onClick: () => onChange(getUpdatedFilterValue(value, option.value, isActive)), children: [_jsx(IconBox, { size: "m", className: cx(isActive && 'text-secondary-400'), icon: isActive ? faCheckSquare : faSquare }), itemRenderer(option)] }, option.value));
        })) : (_jsx("li", { className: styles.notFound, children: "Nothing found :(" })) }));
};
export const Multiselect = ({ onChange, onBlur, value, options, placeholder, placement = 'bottom', className, theme = 'light', }) => {
    const [open, setOpen] = useState(false);
    const nodeId = useFloatingNodeId();
    const [inputValue, setInputValue] = useState('');
    const filteredOptions = useMemo(() => options.filter((o) => o.name.toLowerCase().includes(inputValue.toLowerCase())), [inputValue, options]);
    const { refs, floatingStyles, context } = useFloating({
        nodeId,
        placement,
        open,
        whileElementsMounted: autoUpdate,
        onOpenChange: setOpen,
        middleware: [
            size({
                apply({ rects, elements }) {
                    elements.floating.style.width = `${rects.reference.width - 2}px`;
                },
                padding: 10,
            }),
            offset(5),
        ],
    });
    const { getReferenceProps, getFloatingProps } = useInteractions([
        useFocus(context),
        useDismiss(context),
    ]);
    const { isMounted, status } = useTransitionStatus(context);
    const handleBlur = (event) => {
        var _a;
        if (!((_a = refs.floating.current) === null || _a === void 0 ? void 0 : _a.contains(event.relatedTarget))) {
            setInputValue('');
            setOpen(false);
            onBlur === null || onBlur === void 0 ? void 0 : onBlur(event);
        }
    };
    const handleChange = (event) => {
        updateInput(event.target.value);
    };
    const updateInput = (value) => {
        setInputValue(value);
        setOpen(true);
    };
    const handleFocus = () => {
        updateInput('');
    };
    const inputFieldProps = getReferenceProps({
        ref: refs.setReference,
        value: inputValue,
        onChange: handleChange,
        onFocus: handleFocus,
        onBlur: handleBlur,
    });
    const handleToggle = () => {
        setOpen((v) => !v);
    };
    const inputMarkup = (_jsx(Input, { ...inputFieldProps, placeholder: value ? `${value.length} selected` : placeholder, size: "small", theme: value ? 'filter' : theme, className: className, children: value ? (_jsx(IconBox, { size: "s", className: "cursor-pointer text-greyscale-0", icon: faTimesCircle, onClick: (e) => {
                onChange(undefined);
                e.preventDefault();
            } })) : (_jsx(IconBox, { size: "s", className: cx(styles.chevron, open && styles.chevronActive), icon: faChevronUp, onClick: handleToggle })) }));
    return (_jsxs(_Fragment, { children: [inputMarkup, isMounted && (_jsx(FloatingNode, { id: nodeId, children: _jsx(FloatingPortal, { children: _jsx("div", { ...getFloatingProps({
                            ref: refs.setFloating,
                            className: cx(styles.container, fade.floating, 'overscroll-contain'),
                            style: floatingStyles,
                        }), "data-status": status, children: _jsx(MultiselectList, { onChange: (value) => {
                                onChange(value);
                                setInputValue('');
                            }, options: filteredOptions, value: value }) }) }) }))] }));
};
