import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, Fragment } from 'react';
import { urlRegexp } from '~/common/utils';
import { Link } from './Interactives';
export const ParseLinks = forwardRef(({ children, ...props }, ref) => (_jsx("div", { ref: ref, ...props, children: children
        .split(urlRegexp)
        .filter(Boolean)
        .map((text, index) => {
        if (text.match(urlRegexp)) {
            return (_jsxs(Fragment, { children: [index !== 0 && ' ', _jsx(Link, { href: text, newTab: true, children: text }), index !== children.length - 1 && ' '] }, index));
        }
        return text;
    }) })));
