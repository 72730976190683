import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useUpdateCache } from '~/common/hooks';
import { useFeatureFlags } from '~/common/kits/features';
import { assertQueryData, httpClient } from '~/common/utils';
import { Customer, LoginAsCustomerLink, Stats, } from '~/customers/domain';
import { useCountriesData } from '~/root';
import { qk } from '~/root/query-keys';
import { useCustomerParams } from '../Customer';
import { useInitData } from './customers';
export const useCustomer = () => {
    const { customer_id } = useCustomerParams();
    return useQuery({
        queryKey: qk.customer(customer_id),
        queryFn: () => httpClient.get(`/v1/staff/customers/${customer_id}`, { decoder: Customer }),
    });
};
export const useUpdateCustomersCache = () => {
    const { customer_id } = useCustomerParams();
    return useUpdateCache(qk.customer(customer_id), `Customer ${customer_id} cache is not defined`);
};
export const useCustomerData = assertQueryData(useCustomer);
export const useStats = () => {
    const { customer_id } = useCustomerParams();
    return useQuery({
        queryKey: [...qk.customer(customer_id), 'stats'],
        queryFn: () => httpClient.get(`/v1/staff/customers/${customer_id}/stats`, { decoder: Stats }),
    });
};
export const useStatsData = assertQueryData(useStats);
export const useLoginAsCustomerLink = (id) => {
    return useQuery({
        queryKey: [...qk.customer(id), 'login-as-link'],
        queryFn: () => {
            return httpClient.get(`/v1/staff/customers/${id}/login-as-customer`, {
                decoder: LoginAsCustomerLink,
            });
        },
    });
};
// TODO I LOVE JOINING ON THE FRONTEND SIDE ^^
export const useJoinedCustomerData = () => {
    var _a, _b, _c, _d;
    const customer = useCustomerData();
    const countries = useCountriesData();
    const init = useInitData();
    return useMemo(() => {
        var _a, _b, _c, _d, _e;
        const billingPhoneDialCode = (_a = countries.find(({ value }) => { var _a, _b; return ((_b = (_a = customer.billing_address) === null || _a === void 0 ? void 0 : _a.phone_number) === null || _b === void 0 ? void 0 : _b.country_id) === value; })) === null || _a === void 0 ? void 0 : _a.dial_code;
        const billingCountry = (_b = countries.find(({ value }) => { var _a; return ((_a = customer.billing_address) === null || _a === void 0 ? void 0 : _a.country_id) === value; })) === null || _b === void 0 ? void 0 : _b.name;
        const country = countries.find(({ value }) => customer.country_id === value);
        const phoneDialCode = ((_c = customer.phone_number) === null || _c === void 0 ? void 0 : _c.country_id)
            ? (_d = countries.find(({ value }) => { var _a; return ((_a = customer.phone_number) === null || _a === void 0 ? void 0 : _a.country_id) === value; })) === null || _d === void 0 ? void 0 : _d.dial_code
            : undefined;
        const invoiceCountry = customer.invoicePaymentMethod
            ? countries.find(({ value }) => { var _a; return ((_a = customer.invoicePaymentMethod) === null || _a === void 0 ? void 0 : _a.country_id) === value; })
            : undefined;
        const invoiceCurrency = customer.invoicePaymentMethod
            ? ((_e = init.currencies.find(({ value }) => { var _a; return value === ((_a = customer.invoicePaymentMethod) === null || _a === void 0 ? void 0 : _a.currency); })) === null || _e === void 0 ? void 0 : _e.name) || null
            : null;
        const appliedCustomCustomerTags = customer.tags.filter(({ value: customTagValue }) => init.filter_options.customer_custom_tags.some(({ value }) => customTagValue === value));
        const appliedCustomCustomerTagsList = appliedCustomCustomerTags.map(({ value }) => value);
        return {
            country,
            phoneDialCode,
            billingPhoneDialCode,
            billingCountry,
            invoiceCountry,
            invoiceCurrency,
            appliedCustomCustomerTags,
            appliedCustomCustomerTagsList,
        };
    }, [
        countries,
        (_a = customer.billing_address) === null || _a === void 0 ? void 0 : _a.country_id,
        (_c = (_b = customer.billing_address) === null || _b === void 0 ? void 0 : _b.phone_number) === null || _c === void 0 ? void 0 : _c.country_id,
        customer.country_id,
        customer.invoicePaymentMethod,
        (_d = customer.phone_number) === null || _d === void 0 ? void 0 : _d.country_id,
        customer.tags,
        init.currencies,
        init.filter_options.customer_custom_tags,
    ]);
};
export const useCustomerDeletion = () => {
    const client = useQueryClient();
    const { customer_id } = useCustomerParams();
    return useMutation({
        mutationFn: async (subscriptionsId) => {
            await httpClient.delete(`/v1/staff/customers/${customer_id}`);
            return subscriptionsId;
        },
        onSuccess: (subscriptionsId) => {
            client.removeQueries(qk.customer(customer_id));
            client.invalidateQueries(qk.customersList);
            client.invalidateQueries(qk.customerActivity(customer_id));
            if (subscriptionsId) {
                client.invalidateQueries(qk.subscriptionActivity(subscriptionsId));
            }
        },
    });
};
export const useCustomerSuspension = () => {
    const client = useQueryClient();
    const { customer_id } = useCustomerParams();
    return useMutation({
        mutationFn: (data) => httpClient.post(`/v1/staff/customers/${customer_id}/suspend`, { data, decoder: Customer }),
        onSuccess: (next) => {
            var _a;
            client.setQueryData(qk.customer(customer_id), next);
            client.invalidateQueries(qk.customersList);
            client.invalidateQueries(qk.customerActivity(customer_id));
            if ((_a = next.subscription) === null || _a === void 0 ? void 0 : _a.id) {
                client.invalidateQueries(qk.subscriptionActivity(next.subscription.id));
            }
        },
    });
};
export const useCustomerUnsuspension = () => {
    const client = useQueryClient();
    const { customer_id } = useCustomerParams();
    return useMutation({
        mutationFn: () => httpClient.post(`/v1/staff/customers/${customer_id}/unsuspend`, { decoder: Customer }),
        onSuccess: (next) => {
            var _a;
            client.setQueryData(qk.customer(customer_id), next);
            client.invalidateQueries(qk.customersList);
            client.invalidateQueries(qk.customerActivity(customer_id));
            if ((_a = next.subscription) === null || _a === void 0 ? void 0 : _a.id) {
                client.invalidateQueries(qk.subscriptionActivity(next.subscription.id));
            }
        },
    });
};
export const useGeneralInfoMutation = () => {
    const client = useQueryClient();
    const { customer_id } = useCustomerParams();
    return useMutation({
        mutationFn: (data) => httpClient.patch(`/v1/staff/customers/${customer_id}/general-info`, {
            data,
            decoder: Customer,
        }),
        onSuccess: (next) => {
            var _a;
            client.setQueryData(qk.customer(customer_id), next);
            client.invalidateQueries(qk.customersList);
            client.invalidateQueries(qk.customerActivity(customer_id));
            if ((_a = next.subscription) === null || _a === void 0 ? void 0 : _a.id) {
                client.invalidateQueries(qk.subscriptionActivity(next.subscription.id));
            }
        },
    });
};
export const useOrganizationMutation = () => {
    const client = useQueryClient();
    const { customer_id } = useCustomerParams();
    return useMutation({
        mutationFn: (data) => httpClient.patch(`/v1/staff/customers/${customer_id}/organisation`, {
            data,
            decoder: Customer,
        }),
        onSuccess: (next) => {
            var _a;
            client.setQueryData(qk.customer(customer_id), next);
            client.invalidateQueries(qk.customerActivity(customer_id));
            if ((_a = next.subscription) === null || _a === void 0 ? void 0 : _a.id) {
                client.invalidateQueries(qk.subscriptionActivity(next.subscription.id));
            }
        },
    });
};
export const useCustomerLinksMutation = () => {
    const client = useQueryClient();
    const { customer_id } = useCustomerParams();
    return useMutation({
        mutationFn: (data) => httpClient.patch(`/v1/staff/customers/${customer_id}/links`, { data, decoder: Customer }),
        onSuccess: (next) => {
            var _a;
            client.setQueryData(qk.customer(customer_id), next);
            client.invalidateQueries(qk.customerActivity(customer_id));
            if ((_a = next.subscription) === null || _a === void 0 ? void 0 : _a.id) {
                client.invalidateQueries(qk.subscriptionActivity(next.subscription.id));
            }
        },
    });
};
export const usePaymentMethodsMutation = () => {
    const client = useQueryClient();
    const { customer_id } = useCustomerParams();
    return useMutation({
        mutationFn: (data) => httpClient.patch(`/v1/staff/customers/${customer_id}/billing/payment-methods`, {
            data,
            decoder: Customer,
        }),
        onSuccess: (next) => {
            var _a;
            client.setQueryData(qk.customer(customer_id), next);
            client.invalidateQueries(qk.customerActivity(customer_id));
            if ((_a = next.subscription) === null || _a === void 0 ? void 0 : _a.id) {
                client.setQueryData(qk.subscription(next.subscription.id), (prev) => {
                    var _a;
                    return prev && {
                        ...prev,
                        payment_method: ((_a = next.subscription) === null || _a === void 0 ? void 0 : _a.payment_method) || null,
                        payment_methods: next.payment_methods,
                        defaultPaymentMethod: next.payment_methods.find((paymentMethod) => paymentMethod.default),
                        invoicePaymentMethod: next.payment_methods.find((paymentMethod) => 'company' in paymentMethod),
                    };
                });
                client.invalidateQueries(qk.subscriptionActivity(next.subscription.id));
            }
        },
    });
};
export const useBillingInfoMutation = () => {
    const client = useQueryClient();
    const { customer_id } = useCustomerParams();
    return useMutation({
        mutationFn: (data) => httpClient.patch(`/v1/staff/customers/${customer_id}/billing/info`, {
            data,
            decoder: Customer,
        }),
        onSuccess: (next) => {
            var _a;
            client.setQueryData(qk.customer(customer_id), next);
            client.invalidateQueries(qk.customersList);
            client.invalidateQueries(qk.customerActivity(customer_id));
            if ((_a = next.subscription) === null || _a === void 0 ? void 0 : _a.id) {
                client.invalidateQueries(qk.subscriptionActivity(next.subscription.id));
            }
        },
    });
};
export const useInvoiceMethodMutation = () => {
    const client = useQueryClient();
    const { customer_id } = useCustomerParams();
    return useMutation({
        mutationFn: (data) => httpClient.put(`/v1/staff/customers/${customer_id}/billing/invoice`, {
            data,
            decoder: Customer,
        }),
        onSuccess: (next) => {
            var _a;
            client.setQueryData(qk.customer(customer_id), next);
            client.invalidateQueries(qk.customerActivity(customer_id));
            if ((_a = next.subscription) === null || _a === void 0 ? void 0 : _a.id) {
                client.setQueryData(qk.subscription(next.subscription.id), (prev) => {
                    var _a;
                    return prev && {
                        ...prev,
                        payment_method: ((_a = next.subscription) === null || _a === void 0 ? void 0 : _a.payment_method) || null,
                        payment_methods: next.payment_methods,
                        defaultPaymentMethod: next.payment_methods.find((paymentMethod) => paymentMethod.default),
                        invoicePaymentMethod: next.payment_methods.find((paymentMethod) => 'company' in paymentMethod),
                    };
                });
                client.invalidateQueries(qk.subscriptionActivity(next.subscription.id));
            }
        },
    });
};
export const useSuccessTeamMutation = () => {
    const client = useQueryClient();
    const { customer_id } = useCustomerParams();
    return useMutation({
        mutationFn: (data) => httpClient.patch(`/v1/staff/customers/${customer_id}/success-team`, {
            data,
            decoder: Customer,
        }),
        onSuccess: (next) => {
            var _a, _b, _c;
            client.setQueryData(qk.customer(next.id), next);
            client.invalidateQueries(qk.customerActivity(next.id));
            if ((_a = next.subscription) === null || _a === void 0 ? void 0 : _a.id) {
                client.setQueryData(qk.subscription((_b = next.subscription) === null || _b === void 0 ? void 0 : _b.id), (prev) => prev && { ...prev, success_team: next.success_team });
                client.invalidateQueries(qk.subscriptionActivity((_c = next.subscription) === null || _c === void 0 ? void 0 : _c.id));
            }
        },
    });
};
export const useDedicatedTeamMutation = () => {
    const client = useQueryClient();
    const { customer_id } = useCustomerParams();
    return useMutation({
        mutationFn: (data) => httpClient.patch(`/v1/staff/customers/${customer_id}/dedicated-team`, {
            data,
            decoder: Customer,
        }),
        onSuccess: (next) => {
            var _a, _b, _c;
            client.setQueryData(qk.customer(next.id), next);
            client.invalidateQueries(qk.customerActivity(next.id));
            if ((_a = next.subscription) === null || _a === void 0 ? void 0 : _a.id) {
                client.setQueryData(qk.subscription((_b = next.subscription) === null || _b === void 0 ? void 0 : _b.id), (prev) => prev && { ...prev, dedicated_team: next.dedicated_team });
                client.invalidateQueries(qk.subscriptionActivity((_c = next.subscription) === null || _c === void 0 ? void 0 : _c.id));
            }
        },
    });
};
export const useTreatmentsPricingMutation = () => {
    const client = useQueryClient();
    const { customer_id } = useCustomerParams();
    return useMutation({
        mutationFn: (data) => httpClient.patch(`/v1/staff/customers/${customer_id}/treatments-pricing`, {
            data,
            decoder: Customer,
        }),
        onSuccess: (next) => {
            var _a, _b;
            client.setQueryData(qk.customer(next.id), next);
            client.invalidateQueries(qk.customerActivity(next.id));
            if ((_a = next.subscription) === null || _a === void 0 ? void 0 : _a.id) {
                client.setQueryData(qk.subscription(next.subscription.id), (prev) => prev && { ...prev, treatments: next.treatments });
                client.invalidateQueries(qk.subscriptionActivity((_b = next.subscription) === null || _b === void 0 ? void 0 : _b.id));
            }
        },
    });
};
export const useBillingSettingsMutation = () => {
    const client = useQueryClient();
    const { customer_id } = useCustomerParams();
    return useMutation({
        mutationFn: (data) => httpClient.patch(`/v1/staff/customers/${customer_id}/billing/settings`, {
            data,
            decoder: Customer,
        }),
        onSuccess: (next) => {
            var _a;
            client.setQueryData(qk.customer(next.id), next);
            client.invalidateQueries(qk.customerActivity(next.id));
            if ((_a = next.subscription) === null || _a === void 0 ? void 0 : _a.id) {
                client.invalidateQueries(qk.subscriptionActivity(next.subscription.id));
            }
        },
    });
};
export const useProjectManagersMutation = () => {
    const client = useQueryClient();
    const { customer_id } = useCustomerParams();
    return useMutation({
        mutationFn: (data) => httpClient.patch(`/v1/staff/customers/${customer_id}/project-managers`, {
            data,
            decoder: Customer,
        }),
        onSuccess: (next) => {
            var _a, _b, _c;
            client.setQueryData(qk.customer(next.id), next);
            client.invalidateQueries(qk.customerActivity(next.id));
            if ((_a = next.subscription) === null || _a === void 0 ? void 0 : _a.id) {
                client.setQueryData(qk.subscription((_b = next.subscription) === null || _b === void 0 ? void 0 : _b.id), (prev) => prev && {
                    ...prev,
                    project_managers: next.project_managers,
                    project_managers_list: next.project_managers.map((pm) => ({
                        value: pm.id,
                        name: pm.name,
                    })),
                });
                client.invalidateQueries(qk.subscriptionActivity((_c = next.subscription) === null || _c === void 0 ? void 0 : _c.id));
            }
        },
    });
};
export const useCustomerFeatureFlags = () => {
    const queryClient = useQueryClient();
    const { features: targetFeatures, customer_account_id: targetable_id, subscription, } = useCustomerData();
    const { features: initFeatures } = useInitData();
    const updateCustomer = useUpdateCustomersCache();
    return useFeatureFlags({
        targetable_id,
        targetFeatures,
        initFeatures,
        onMutationSuccess: (features) => {
            updateCustomer((data) => ({
                ...data,
                features,
            }));
            (subscription === null || subscription === void 0 ? void 0 : subscription.id) && queryClient.invalidateQueries(qk.subscription(subscription.id));
        },
    });
};
