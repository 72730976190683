import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faBriefcase, faClock, faInfoCircle, faPalette, faShapes, faUsdSquare, faUserCircle, faUserFriends, } from '@fortawesome/pro-regular-svg-icons';
import { faAnglesRight } from '@fortawesome/pro-solid-svg-icons';
import { Button, DateDistance, IconBox, Link, Tooltip, useModalContext } from '~/common/components';
import { UserCard } from '~/common/kits/usercard';
import { capitalize, cx, pluralize, pluralizeWord } from '~/common/utils';
import { CUSTOMERS_URL } from '~/customers/hooks';
import { useOrderData } from '~/orders/hooks';
import { CapacityDistribution, CountriesTime, StatusTransition } from '~/orders/Order';
import { useCurrentUserData } from '~/root';
import { useInformationBar } from './hooks';
import { InfoBarItem } from './InfoBarItem';
import { OrderTags } from './OrderTags';
export const InformationBar = () => {
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    const { deadline, designTeam, order, customer, manager, countries, transitions } = useInformationBar();
    const { treatments } = useOrderData();
    // TODO it's a fragile way of accesing it,
    // revise when treatments implementation is updated on BE
    const ADSTreatment = treatments.find(({ id }) => id === 5);
    return (_jsxs("div", { className: "flex flex-col space-y-1", children: [_jsxs(InfoBarItem, { icon: faPalette, title: "Design Status", children: [_jsxs("div", { className: "flex gap-[4px]", children: [_jsx("h4", { className: cx('font-brand-t1m inline-flex', {
                                    'text-primary-300': order.status === 'Completed',
                                    'text-danger-400': order.status === 'Cancelled',
                                }), children: order.statusLabel }), order.cancellationReason && (_jsx(IconBox, { "data-tt": order.cancellationReason, "data-tt-placement": "right", "data-tt-class": "max-w-[180px]", size: "s", className: "cursor-help", icon: faInfoCircle }))] }), permissions.ordersUpdate && order.statusLabel && transitions && (_jsx(Button.Icon, { onClick: modalOpener(StatusTransition, {
                            currentStatusLabel: order.statusLabel,
                            onOpenCapacityDistribution: modalOpener(CapacityDistribution, {
                                statusTransition: true,
                                type: 'distribution',
                            }),
                            transitions,
                        }), size: "m", color: "secondary", className: "absolute right-1 top-1", icon: faAnglesRight }))] }), order.status === 'Completed' && (_jsx(InfoBarItem, { icon: faUsdSquare, title: "Payment Status", children: _jsx("h4", { className: cx('font-brand-t1m', {
                        'text-primary-300': order.paymentStatus === 'paid',
                    }), children: capitalize(order.paymentStatus) }) })), _jsxs(InfoBarItem, { icon: faClock, title: "Deadline", children: [_jsx(DateDistance, { className: cx('font-brand-t1m w-fit', deadline.expired && 'text-danger-500'), to: deadline.absolute }, deadline.absolute.toString()), _jsx(CountriesTime, { date: deadline.absolute, countries: countries })] }), order.slides && (_jsxs(InfoBarItem, { icon: faShapes, title: "Order", children: [order.slides - ADSTreatment.value > 0 && (_jsxs("h4", { className: "flex items-center gap-1", children: [_jsx("span", { className: "font-brand-t1m text-greyscale-600", children: pluralizeWord('slide', order.slides - ADSTreatment.value) }), order.slides - ADSTreatment.value > 0 && (_jsx(Tooltip, { className: "w-[160px]", content: _jsx("div", { className: "space-y-[4px]", children: treatments
                                        .filter(({ value, id }) => !!value && id !== 5)
                                        .map(({ name, value, id }) => (_jsxs("div", { className: "flex py-[4px] justify-between gap-2 font-brand-c1", children: [_jsx("span", { className: "text-greyscale-500", children: name }), _jsx("span", { className: "text-greyscale-600", children: value })] }, id))) }), children: _jsx(IconBox, { icon: faInfoCircle, size: "s" }) }))] })), ADSTreatment.value > 0 && _jsx("div", { className: "font-brand-t1m flex items-center", children: "ADS" }), order.customName && (_jsx("p", { "data-tt-class": "max-w-[240px]", "data-tt-placement": "right", className: "font-brand-b1 text-greyscale-500 truncate", children: order.customName }))] })), customer.name && (_jsxs(InfoBarItem, { icon: faBriefcase, title: "Customer", children: [_jsx("h4", { className: "font-brand-t1m truncate", children: _jsx(UserCard, { id: customer.id, children: customer.owner_id ? (_jsx(Link, { size: "xl-bold", to: `${CUSTOMERS_URL}/${customer.owner_id}`, children: customer.name })) : (_jsx("span", { children: customer.name })) }) }), customer.company && (_jsx("h5", { className: "font-brand-b1 text-greyscale-500 truncate w-fit", "data-tt": customer.company, "data-tt-placement": "right", children: customer.company }))] })), manager && (_jsx(InfoBarItem, { icon: faUserCircle, title: "Project manager", children: _jsx("h4", { className: "font-brand-t1m", children: manager }) })), designTeam.name && (_jsxs(InfoBarItem, { icon: faUserFriends, title: "Design team", children: [_jsx("h4", { className: "font-brand-t1m", children: designTeam.name }), !!designTeam.tagged.length && (_jsxs("div", { className: "relative flex items-start gap-1", children: [_jsx(IconBox, { "data-tt": `Tag team${pluralize(designTeam.tagged.length)}`, "data-tt-placement": "right", "data-tt-class": "max-w-[240px]", size: "s", className: "mt-[4px]", icon: faUserFriends }), _jsx("p", { className: "font-brand-b1 text-greyscale-500", children: designTeam.tagged.join(', ') })] }))] })), _jsx(OrderTags, {})] }));
};
