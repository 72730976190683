import { z } from 'zod';
import { FeatureValues } from '~/common/kits/features';
import { TreatmentsTable } from '~/common/other';
import { DateFromIsoString } from '~/common/utils';
import { Country } from '~/offices/domain';
import { Currency, UploadedFile } from '~/root';
import { CustomerProjectManager, DedicatedTeam, PaymentMethod, SubscriptionProduct, SubscriptionStatus, SuccessTeam, } from './customer';
export const UploadWindow = z.object({
    timezone: z.string(),
    on_day: z.enum(['today', 'tomorrow']),
    submitted_at_hour: z.string(),
    submitted_before_hour: z.string(),
});
export const UpdateChange = z.object({
    before: z.union([z.string(), z.number()]).nullable(),
    after: z.union([z.string(), z.number()]).nullable(),
    name: z.string(),
});
const UpdateChanges = z.object({
    retainer: z.array(UpdateChange).optional(),
    techpack: z.array(UpdateChange).optional(),
    general: z.array(UpdateChange),
});
const SystemEventType = z.enum(['renewal', 'cancellation', 'trial', 'activation']);
export const ScheduledEventType = z.enum(['scheduled_update']);
const UpcomingEvent = z.object({
    date: DateFromIsoString,
    description: z.string(),
    cancellable: z.boolean(),
});
const ScheduledEvent = UpcomingEvent.extend({
    type: ScheduledEventType,
    changes: z.object({
        retainer: z.array(UpdateChange).optional(),
        techpack: z.array(UpdateChange).optional(),
        general: z.array(UpdateChange),
    }),
});
const SystemEvent = UpcomingEvent.extend({
    type: SystemEventType,
    invoice_preview_url: z.string().nullable(),
});
export const Subscription = z
    .object({
    id: z.number(),
    customer_account_id: z.number().int().positive(),
    owner_id: z.number(),
    owner_name: z.string(),
    owner_email: z.string(),
    credit_balance: z.number(),
    features: FeatureValues,
    products: z.array(SubscriptionProduct),
    currency: Currency,
    renewal_period: z.number(),
    company_name: z.string().nullable(),
    partnership_logo: UploadedFile.nullable(),
    country: Country.nullable(),
    capacity_overview_link: z.string().nullable(),
    send_invoice: z.boolean(),
    renews_at: DateFromIsoString.nullable(),
    created_at: DateFromIsoString,
    starts_at: DateFromIsoString.nullable(),
    updated_at: DateFromIsoString.nullable(),
    trial_ends_at: DateFromIsoString.nullable(),
    ends_at: DateFromIsoString.nullable(),
    is_trusted: z.boolean(),
    payment_method: PaymentMethod.nullable(),
    payment_methods: z.array(PaymentMethod),
    upload_window: UploadWindow.nullable(),
    dedicated_team: DedicatedTeam,
    status: SubscriptionStatus,
    success_team: SuccessTeam,
    treatments: TreatmentsTable,
    events: z.array(z.union([ScheduledEvent, SystemEvent])).nullable(),
    project_managers: z.array(CustomerProjectManager),
    timezone_id: z.number().nullable(),
})
    .transform(({ payment_methods, ...subscription }) => ({
    ...subscription,
    payment_methods,
    project_managers_list: subscription.project_managers.map((pm) => ({
        name: pm.name,
        value: pm.id,
    })),
    defaultPaymentMethod: payment_methods.find((paymentMethod) => paymentMethod.default),
    invoicePaymentMethod: payment_methods.find((paymentMethod) => 'company' in paymentMethod),
}));
