import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { Badge, Button, FilterSwitcher, FiltersButton, IconBox, LayoutHeader, LayoutTitle, ListCount, Popover, } from '~/common/components';
import { CategoryMultiselect, Filter, Search, getFilterProps, getSearchFilterDataFromOptions, useParsedQuery, } from '~/common/kits/table';
import { mergedTagFilters } from '../hooks';
const searchFilters = ['customer', 'company'];
const resetFilters = (filterMap) => {
    const newFilterMap = { ...filterMap };
    const filters = [
        'customer_system_tags',
        'customer_custom_tags',
        'customer_type',
        'custom_portal',
        'customer_role',
        'show_deleted',
    ];
    for (const option of filters) {
        newFilterMap[option] = undefined;
    }
    return newFilterMap;
};
const placeholderMapper = (option) => {
    switch (option) {
        case 'customer':
            return 'Enter email';
        case 'company':
            return 'Enter company name';
    }
};
const Item = ({ value, color, name, hint }) => (_jsxs("div", { className: "flex items-center justify-between w-full pr-[4px]", children: [_jsx(Badge, { color: color, children: name }), hint && (_jsx(IconBox, { "data-tt": hint, size: "s", className: "pointer-events-auto", icon: faInfoCircle }))] }, value + name));
export const Header = ({ tableFilters, customersCount }) => {
    const { queryParams, onFilterChange, updateFilters } = useParsedQuery();
    const searchFilterData = getSearchFilterDataFromOptions(searchFilters, queryParams.filter);
    const filterProps = getFilterProps(tableFilters, queryParams.filter, onFilterChange);
    const getAppliedFiltersAmount = () => {
        const appliedQueryFilters = Object.keys(queryParams.filter);
        const omittedFiltersByDefault = appliedQueryFilters.filter((key) => !searchFilters.includes(key));
        const omittedMergeFilters = omittedFiltersByDefault.filter((key) => !mergedTagFilters.includes(key));
        return (omittedMergeFilters.length +
            Math.min(1, omittedFiltersByDefault.length - omittedMergeFilters.length));
    };
    const appliedFiltersAmount = getAppliedFiltersAmount();
    return (_jsxs(LayoutHeader, { className: "flex justify-between items-center", children: [_jsxs("div", { className: "flex items-baseline", children: [_jsx(LayoutTitle, { children: "Customers" }), _jsx(ListCount, { name: "Total user", className: "font-brand-b1 text-greyscale-400 ml-2", amount: customersCount })] }), _jsx(Search, { className: "ml-auto mr-3", filters: searchFilters, selectedFilter: searchFilterData.selectedFilter, value: searchFilterData.value, onChange: onFilterChange, updateFilters: updateFilters, placeholderMapper: placeholderMapper }), _jsx(Popover, { compensateOffset: 8, className: "space-y-2 p-2 pt-[12px]", placement: "bottom-end", trigger: (props) => (_jsx(FiltersButton, { ...props, appliedFiltersAmount: appliedFiltersAmount })), content: () => (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex items-center justify-between mb-[-4px]", children: [_jsx("h3", { className: "font-brand-t2m text-greyscale-600", children: "Filters" }), appliedFiltersAmount > 0 && (_jsx(Button, { color: "text-secondary", size: "xs", onClick: () => updateFilters(resetFilters), children: "Reset" }))] }), _jsx(CategoryMultiselect, { reset: () => updateFilters((prev) => ({
                                ...prev,
                                ...Object.fromEntries(mergedTagFilters.map((filterName) => [filterName, undefined])),
                            })), categories: [
                                {
                                    list: [
                                        {
                                            ...filterProps('customer_system_tags'),
                                            itemRenderer: (props) => _jsx(Item, { ...props }),
                                        },
                                        {
                                            ...filterProps('customer_custom_tags'),
                                            itemRenderer: (props) => _jsx(Item, { ...props }),
                                        },
                                    ],
                                },
                            ], placeholder: "Tag type" }), _jsx(Filter, { ...filterProps('customer_type'), placeholder: "Customer type", className: "max-w-[240px]", theme: "light" }), _jsx(Filter, { ...filterProps('custom_portal'), placeholder: "Custom portal", className: "max-w-[240px]", theme: "light" }), _jsx(Filter, { ...filterProps('customer_role'), placeholder: "Customer role", className: "max-w-[240px]", theme: "light" }), _jsx("hr", { className: "h-[1px] w-full text-greyscale-200" }), _jsx(FilterSwitcher, { label: "Show deleted customers", className: "min-w-fit max-w-fit", value: queryParams.filter.show_deleted, onChange: (value) => onFilterChange('show_deleted', value) })] })) })] }));
};
