import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { $createLinkNode, $isAutoLinkNode, $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $findMatchingParent, mergeRegister } from '@lexical/utils';
import { $createTextNode, $getSelection, $isRangeSelection, CLICK_COMMAND, COMMAND_PRIORITY_LOW, createCommand, } from 'lexical';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Input, Popover } from '~/common/components';
import { cx, isLink } from '~/common/utils';
import { $selectNodeAsWhole, getIsClickedAtEndOfTheNode, getIsSelectionInLink, getNativeSelectionInsideEditor, getSelectedNode, notHandled, sanitizeUrl, } from './utils';
export const EDIT_LINK_COMMAND = createCommand('EDIT_LINK_COMMAND');
export const HOTKEY_COMMAND = createCommand('HOTKEY_COMMAND');
export const EditLinkPlugin = () => {
    const [editor] = useLexicalComposerContext();
    const [popoverReference, setPopoverReference] = useState(null);
    const inputReference = useRef(null);
    const refocusEditor = useCallback(() => {
        var _a;
        try {
            editor.focus();
        }
        catch (_b) {
            (_a = editor === null || editor === void 0 ? void 0 : editor.getRootElement()) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [editor]);
    const [title, setTitle] = useState('');
    const [linkUrl, setLinkUrl] = useState('https://');
    const [isEdit, setIsEdit] = useState(false);
    const isLinkValid = isLink(linkUrl);
    const closePopover = useCallback(() => {
        setPopoverReference(null);
        refocusEditor();
    }, [refocusEditor]);
    useEffect(() => {
        const linkClickHandler = notHandled(() => {
            var _a;
            const selection = $getSelection();
            if (!selection || !$isRangeSelection(selection)) {
                return;
            }
            const node = getSelectedNode(selection);
            const linkParent = $findMatchingParent(node, $isLinkNode);
            const link = (linkParent === null || linkParent === void 0 ? void 0 : linkParent.getURL()) || ($isLinkNode(node) ? node.getURL() : '');
            const nativeSelection = getNativeSelectionInsideEditor(editor);
            if (!nativeSelection || !link) {
                return;
            }
            const linkNode = $isLinkNode(node) ? node : linkParent;
            if (linkNode) {
                if (getIsClickedAtEndOfTheNode(linkNode, selection)) {
                    return;
                }
                $selectNodeAsWhole(linkNode, selection);
            }
            // open editable popover
            const reference = (_a = nativeSelection.focusNode) === null || _a === void 0 ? void 0 : _a.parentElement;
            if (reference && !reference.matches('p')) {
                setPopoverReference(reference === popoverReference ? null : reference);
                if (reference !== popoverReference) {
                    setLinkUrl(link);
                    setTitle(reference.textContent || '');
                    setIsEdit(true);
                }
            }
        });
        const addLinkHandler = notHandled(() => {
            if (getIsSelectionInLink()) {
                return linkClickHandler();
            }
            const selection = $getSelection();
            const nativeSelection = getNativeSelectionInsideEditor(editor);
            if (!selection || !nativeSelection || nativeSelection.rangeCount === 0) {
                return;
            }
            setLinkUrl('');
            setTitle(selection.getTextContent());
            setIsEdit(false);
            const cursor = nativeSelection.getRangeAt(0);
            const endContainer = cursor.endContainer;
            const element = endContainer instanceof HTMLElement ? endContainer : cursor;
            setPopoverReference(element);
        });
        const handleHotkey = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key.toUpperCase() === 'K') {
                event.preventDefault();
                addLinkHandler();
                return true;
            }
            return false;
        };
        return mergeRegister(editor.registerCommand(CLICK_COMMAND, linkClickHandler, COMMAND_PRIORITY_LOW), editor.registerCommand(EDIT_LINK_COMMAND, addLinkHandler, COMMAND_PRIORITY_LOW), editor.registerCommand(HOTKEY_COMMAND, handleHotkey, COMMAND_PRIORITY_LOW), editor.registerRootListener((rootElement) => {
            const handleKeyDown = (event) => {
                editor.dispatchCommand(HOTKEY_COMMAND, event);
            };
            rootElement === null || rootElement === void 0 ? void 0 : rootElement.addEventListener('keydown', handleKeyDown);
            return () => rootElement === null || rootElement === void 0 ? void 0 : rootElement.removeEventListener('keydown', handleKeyDown);
        }));
    }, [closePopover, editor, popoverReference]);
    const handleEnter = (event) => {
        if (event.key === 'Enter' && isLinkValid) {
            event.preventDefault();
            if (isEdit) {
                handleLinkUpdate();
            }
            else {
                handleLinkCreate();
            }
        }
    };
    const handleLinkUpdate = () => {
        closePopover();
        if (linkUrl === '' || title === '') {
            return;
        }
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl(linkUrl));
        editor.update(() => {
            const selection = $getSelection();
            if (!$isRangeSelection(selection)) {
                return;
            }
            const parent = getSelectedNode(selection).getParent();
            if (!$isAutoLinkNode(parent) && !$isLinkNode(parent)) {
                return;
            }
            const linkNode = $createLinkNode(parent.getURL(), {
                rel: parent.__rel,
                target: parent.__target,
                title: parent.__title,
            });
            const textNode = $createTextNode(title);
            linkNode.append(textNode);
            textNode.select();
            parent.replace(linkNode);
        });
    };
    const handleLinkCreate = () => {
        closePopover();
        if (linkUrl === '' || title === '') {
            return;
        }
        editor.update(() => {
            const selection = $getSelection();
            if (!$isRangeSelection(selection)) {
                return;
            }
            if (!selection.isCollapsed()) {
                selection.removeText();
            }
            const linkNode = $createLinkNode(linkUrl);
            linkNode.append($createTextNode(title));
            selection.insertNodes([linkNode]);
            linkNode.select();
        });
    };
    return (_jsx(Popover, { placement: "top-start", reference: popoverReference, onClose: closePopover, className: "w-[364px] space-y-2", focusRef: inputReference, content: () => (_jsxs(_Fragment, { children: [_jsx(Input, { ref: inputReference, title: "Title to display", className: "link-input", placeholder: "Add link title", value: title, onChange: (event) => setTitle(event.target.value), onKeyDown: handleEnter }), _jsx(Input, { title: "Link", className: "link-input", placeholder: "Add link url", value: linkUrl, onChange: (event) => setLinkUrl(event.target.value), onKeyDown: handleEnter }), _jsxs("div", { className: "flex items-center justify-end gap-2", children: [isEdit && (_jsx(Button, { color: "text-secondary", size: "xs", className: "mr-2", onClick: () => {
                                editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
                                closePopover();
                            }, children: "Remove link" })), _jsx(Button, { onClick: closePopover, children: "Cancel" }), _jsx(Button, { onClick: isEdit ? handleLinkUpdate : handleLinkCreate, color: "secondary", disabled: !isLinkValid, "data-tt": cx(!isLinkValid && 'Please enter valid link url'), children: isEdit ? 'Update link' : 'Add link' })] })] })) }));
};
