import { useEffect } from 'react';
import { copyToClipboard } from '~/common/utils';
export const getReadableLinkText = (text) => {
    try {
        const url = new URL(text);
        const pathname = url.pathname !== '/' ? url.pathname : '';
        const decodedUrl = decodeURI(url.hostname + pathname + url.search);
        return decodedUrl;
    }
    catch (_a) {
        return text;
    }
};
// This is necessary when user copies text from link
// with getReadableLinkText as it's content to transform it
// back to url with protocol
export const useClipboardLinkModifier = (href) => {
    useEffect(() => {
        const copyHandler = () => {
            var _a;
            const selectedText = ((_a = window.getSelection()) === null || _a === void 0 ? void 0 : _a.toString()) || '';
            // This is for cases when you trigger copyToClipboard manually
            // see: /orders/Order/BriefSection/Files/FilesSection.tsx
            if (selectedText.includes(href))
                return;
            const readableUrl = getReadableLinkText(href);
            if (selectedText.includes(readableUrl)) {
                const modifiedSelection = selectedText.replace(readableUrl, href);
                copyToClipboard(modifiedSelection);
            }
        };
        document.addEventListener('copy', copyHandler);
        return () => document.removeEventListener('copy', copyHandler);
    }, [href]);
};
