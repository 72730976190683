import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons';
import { Link, Tags } from '~/common/components';
import { Stars, TeamsView } from '../components';
export const tableConfig = {
    columns: [
        {
            key: 'id',
            name: 'ID',
            width: '106px',
        },
        {
            key: 'customer',
            name: 'Customer',
            width: 'minmax(100px, 1fr)',
            cellRenderer: ({ cellData, rowData }) => (_jsxs(_Fragment, { children: [_jsx("span", { className: "truncate h-[20px]", children: cellData.name }), _jsx("div", { className: "flex items-center absolute bottom-2 h-[18px] gap-[4px]", children: _jsx(Tags, { tags: rowData.tags, tagSize: "small", className: "max-w-[calc(100vw-250px)]" }) })] })),
            noTruncate: true,
            getClassName: () => 'relative flex max-w-full',
        },
        {
            key: 'slides',
            name: 'Slides',
            width: '90px',
        },
        {
            key: 'manager',
            name: 'PM',
            cellRenderer: ({ cellData }) => { var _a; return (_a = cellData === null || cellData === void 0 ? void 0 : cellData.name) !== null && _a !== void 0 ? _a : '—'; },
            width: '18%',
        },
        {
            key: 'team',
            name: 'Design Team',
            cellRenderer: ({ cellData, rowData }) => cellData ? _jsx(TeamsView, { name: cellData.name, tag_teams: rowData.tag_teams }) : '—',
            width: '18%',
        },
        {
            key: 'rating',
            name: 'Rating',
            width: '144px',
            cellRenderer: ({ cellData }) => _jsx(Stars, { rating: cellData }),
        },
        {
            key: 'file_url',
            name: '',
            width: '48px',
            cellRenderer: ({ cellData }) => {
                return (cellData && (_jsx(Link.Icon, { icon: faArrowDownToLine, color: "secondary", className: "relative -top-[3px]", size: "s", href: cellData, newTab: true })));
            },
        },
    ],
    sortable: ['id', 'slides', 'rating'],
    getCellClassName: () => 'h-[78px] items-baseline pt-[14px]',
};
