import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LineBreaks, ParseLinks } from '~/common/components';
import { useOrderData } from '~/orders/hooks';
import { Section } from '~/orders/Order';
import { BriefDetails } from './Details';
import { FilesSubSection, FilesSection } from './Files';
import { Objectives } from './Objectives';
export const BriefSection = () => {
    var _a;
    const { brief } = useOrderData();
    return (_jsx(Section, { type: "brief", children: _jsxs("div", { className: "space-y-[20px]", children: [_jsxs("div", { className: "space-y-[12px]", children: [_jsx("h4", { className: "font-brand-t1m text-greyscale-600", children: "Brief" }), _jsx(LineBreaks, { className: "font-brand-b1 bg-greyscale-50 border-greyscale-200 border rounded p-[12px] max-h-[min(360px,_40vh)] overflow-auto", renderParagraph: (paragraph) => _jsx(ParseLinks, { children: paragraph }), children: brief.brief })] }), _jsx(FilesSection, {}), _jsx(BriefDetails, {}), _jsx(Objectives, {}), !!((_a = brief.reference_files) === null || _a === void 0 ? void 0 : _a.length) && (_jsx(FilesSubSection, { files: brief.reference_files, tag: "order_brief_reference", title: "References" }))] }) }));
};
