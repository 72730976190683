import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { arrow, autoUpdate, flip, FloatingArrow, FloatingFocusManager, FloatingNode, FloatingPortal, offset, shift, size, useClick, useDismiss, useFloating, useFloatingNodeId, useInteractions, useTransitionStatus, } from '@floating-ui/react';
import { useCallback, useEffect, useRef, useState, } from 'react';
import { cx } from '~/common/utils';
import fade from '~/styles/fade.module.scss';
import { outsidePress, withFloatingTree } from './Floating';
import { styles } from './Tooltip';
export const Popover = withFloatingTree(({ trigger, color = 'white', className, placement = 'bottom', fallbackPlacements, compensateOffset = 0, noDismiss = false, noAncestorScroll = false, onClose, onClick, externalState, openOnMount = false, matchTriggerWidth = false, content, showArrow = false, padding = 8, reference, focusRef, }) => {
    const internalState = useState(openOnMount);
    const [open, setOpen] = externalState !== null && externalState !== void 0 ? externalState : internalState;
    const close = useCallback(() => setOpen(false), [setOpen]);
    const arrowRef = useRef(null);
    const nodeId = useFloatingNodeId();
    const { floatingStyles, refs, context } = useFloating({
        nodeId,
        placement,
        open,
        onOpenChange: setOpen,
        middleware: [
            offset(12 - compensateOffset),
            flip({ fallbackPlacements }),
            shift({ padding }),
            arrow({ element: arrowRef }),
            size({
                apply({ elements }) {
                    var _a, _b;
                    if (matchTriggerWidth) {
                        Object.assign((_b = (_a = refs.floating.current) === null || _a === void 0 ? void 0 : _a.style) !== null && _b !== void 0 ? _b : {}, {
                            width: `${elements.reference.getBoundingClientRect().width}px`,
                            maxWidth: `${elements.reference.getBoundingClientRect().width}px`,
                        });
                    }
                },
            }),
        ],
        whileElementsMounted: autoUpdate,
    });
    // Virtual elements are not allowed to be passed to useFloating({elements}) :shrug:
    useEffect(() => {
        if (trigger)
            return;
        refs.setPositionReference(reference);
        setOpen(!!reference);
    }, [reference, refs, setOpen, trigger]);
    const { getReferenceProps, getFloatingProps } = useInteractions([
        useClick(context),
        useDismiss(context, {
            ancestorScroll: !noAncestorScroll,
            enabled: !noDismiss,
            outsidePress,
        }),
    ]);
    const { isMounted, status } = useTransitionStatus(context);
    return (_jsxs(_Fragment, { children: [reference === undefined &&
                trigger(getReferenceProps({ ref: refs.setReference }), open), isMounted && (_jsx(FloatingNode, { id: nodeId, children: _jsx(FloatingPortal, { children: _jsx(FloatingFocusManager, { context: context, initialFocus: focusRef || refs.floating, children: _jsxs("div", { ...getFloatingProps({
                                ref: refs.setFloating,
                                className: cx('pointer-events-auto z-20 focus-visible:outline-none', styles.tooltip, styles[color], fade.floating, className),
                                style: floatingStyles,
                                onClick,
                            }), "data-status": status, onTransitionEnd: () => status === 'close' && (onClose === null || onClose === void 0 ? void 0 : onClose()), children: [showArrow && (_jsx(FloatingArrow, { context: context, ref: arrowRef, width: 12, height: 6, className: cx(styles.arrow, styles[color]), tipRadius: 2, strokeWidth: 1 })), content({ onClose: close })] }) }) }) }))] }));
});
