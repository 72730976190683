import { z } from 'zod';
import { getDateWithoutTime, isValidPeriod, joinTruthy } from './data';
function formatZodError(error, indent = false) {
    const messages = [];
    error.issues.forEach((issue, index) => {
        const issuePath = issue.path.join('.');
        if (issue.code === 'invalid_union' && issue.unionErrors) {
            messages.push(`${issuePath}: invalid union`, issue.unionErrors.map((e) => formatZodError(e, true)).join('\n'));
        }
        else {
            const message = 'expected' in issue
                ? `expected: ${issue.expected}, received: ${issue.received}`
                : issue.message;
            messages.push(`${indent ? '  ' : ''}${issuePath}\n${indent ? '  ' : ''} └─ ${message}${indent && index !== error.issues.length - 1 ? '' : '\n'}`);
        }
    });
    return messages.join('\n');
}
export class DecodingError extends Error {
    constructor(payload, info) {
        const message = joinTruthy(['Unexpected data:', info, formatZodError(payload)]);
        super(message);
        this.name = 'DecodingError';
    }
}
export const safeDecode = (params) => {
    var _a;
    const result = params.decoder.safeParse(params.value);
    return result.success
        ? { success: result.data, error: null }
        : { success: null, error: new DecodingError(result.error, (_a = params.getInfo) === null || _a === void 0 ? void 0 : _a.call(params)) };
};
export const unsafeDecode = (params) => {
    const result = safeDecode(params);
    if (result.error !== null) {
        throw result.error;
    }
    return result.success;
};
export function decodeOrNull(value, decoder) {
    return safeDecode({ value, decoder }).success;
}
const dateRegex = new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{6}Z$');
const timelessDateRegex = new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}$');
/**
 * Common decoders
 */
export const DateFromIsoString = z
    .string()
    .refine((value) => dateRegex.test(value) || timelessDateRegex.test(value), {
    message: 'Invalid date string format',
})
    .transform((value, ctx) => {
    // Convert the string to a Date object
    const date = new Date(value);
    if (!Number.isNaN(date.getTime())) {
        return date;
    }
    ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid date',
    });
    return z.NEVER;
});
export const TimelessDateFromIsoString = DateFromIsoString.transform((date) => {
    date.setMinutes(date.getTimezoneOffset());
    return date;
});
export const urlRegexp = /((?:(?:[a-z]+:)?\/\/)(?:\S+(?::\S*)?@)?(?:localhost|(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?:(?:[a-z\u00a1-\uffff0-9][-_]*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#][^\s"]*)?)/gi;
export function isLink(value) {
    try {
        new URL('', value);
        return true;
    }
    catch (_a) {
        return false;
    }
}
export const Link = z.string().refine(isLink);
/**
 * FlashMessage
 */
export const FlashMessageSuccess = z.object({
    type: z.literal('success'),
    message: z.string(),
});
export const FlashMessageFailure = z.object({
    type: z.literal('error'),
    message: z.string(),
});
export const FlashMessage = z.union([FlashMessageSuccess, FlashMessageFailure]);
export const PeriodWithFallback = z
    .string()
    .refine(isValidPeriod)
    .catch('1-week');
export const DateWithFallback = TimelessDateFromIsoString.catch(() => getDateWithoutTime());
