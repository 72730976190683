import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faBriefcase, faEnvelope, faFileImage, faPalette, faPhoneAlt, faSitemap, faTag, faTimes, } from '@fortawesome/pro-regular-svg-icons';
import { AvatarPane, Button, Copyable, CountryInfoBar, GeneralInfoHeader, IconButton, ImageLoader, InfoBar, ModalCore, Tooltip, useModalContext, } from '~/common/components';
import { useModalProps } from '~/common/hooks';
import { formatPhoneNumber, getColorsFromNumber } from '~/common/utils';
import { useCustomerData, useJoinedCustomerData } from '~/customers/hooks';
import { useCurrentUserData } from '~/root';
import { AccountStyles } from '../AccountStyles';
import { CreditsBanner } from '../CreditsBanner';
import { CustomerTags } from './CustomerTags';
import { EditCustomerInfo } from './EditCustomerInfo';
export const CustomerInfo = () => {
    var _a, _b, _c, _d;
    const customer = useCustomerData();
    const { permissions } = useCurrentUserData();
    const { phoneDialCode, country } = useJoinedCustomerData();
    const { modalOpener } = useModalContext();
    const logoModal = useModalProps();
    const colorSet = getColorsFromNumber(customer.id);
    const link = (_b = (_a = customer.partnership_logo) === null || _a === void 0 ? void 0 : _a.link) !== null && _b !== void 0 ? _b : '';
    const customerHasStyles = !!customer.styles.custom_styles.length ||
        !!customer.styles.custom_portal_styles.length ||
        !!customer.styles.archived_styles.length;
    return (_jsxs("div", { className: "bg-greyscale-0 pb-2 rounded overflow-hidden", children: [_jsx(AvatarPane, { name: customer.name, avatarLink: (_c = customer.avatar) === null || _c === void 0 ? void 0 : _c.link, colorSet: colorSet, children: permissions.customersUpdate && (_jsx(Button, { onClick: modalOpener(EditCustomerInfo), color: "grey", children: "Edit" })) }), _jsxs("div", { className: "px-4", children: [_jsx(GeneralInfoHeader, { name: customer.name, created_at: customer.created_at, last_update: customer.last_update }), _jsx(CreditsBanner, { className: "mt-2", amount: customer.credits }), _jsxs("ul", { className: "mt-1", children: [_jsx(InfoBar, { hint: "Tags", icon: faTag, text: _jsx(CustomerTags, {}) }), _jsx(InfoBar, { icon: faPhoneAlt, text: phoneDialCode && customer.phone_number
                                    ? formatPhoneNumber(phoneDialCode, customer.phone_number.number)
                                    : '—', hint: "Contact phone number" }), country && (_jsx(CountryInfoBar, { country_code: country.country_code, name: country.name, timezone_id: (_d = customer.subscription) === null || _d === void 0 ? void 0 : _d.timezone_id })), _jsx(InfoBar, { icon: faEnvelope, text: customer.email ? _jsx(Copyable, { text: customer.email }) : null, hint: "Contact email" }), _jsx(InfoBar, { icon: faBriefcase, text: customer.company, hint: "Company name" }), _jsx(InfoBar, { icon: faSitemap, text: customer.department, hint: "Department in the company. Only for internal use" }), _jsx(InfoBar, { icon: faPalette, text: customerHasStyles ? (_jsx(Button, { color: "text-secondary", size: "xs", onClick: modalOpener(AccountStyles), children: "Account styles" })) : (_jsx("span", { children: "Account styles" })) }), _jsx(InfoBar, { className: "cursor-pointer", onClick: link ? logoModal.open : undefined, icon: faFileImage, hint: "Partnership logo. Used on the capacity overview page", text: link ? (_jsx(Tooltip, { className: "w-30 p-0", placement: "right", noArrow: true, content: _jsx(ImageLoader, { className: "rounded w-full", src: link }), children: _jsx("span", { children: "Partnership logo" }) })) : null }), _jsxs(ModalCore, { className: "relative w-[588px] rounded-[16px]", ...logoModal, children: [_jsx(IconButton, { className: "absolute right-2 top-2 w-4 h-4 text-[32px] text-greyscale-500 focus-visible:ring", icon: faTimes, onClick: logoModal.onClose }), _jsx(ImageLoader, { className: "rounded-[16px] object-cover w-full", src: link })] })] })] })] }));
};
