import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faEnvelope, faLock } from '@fortawesome/pro-regular-svg-icons';
import { Deadline, IconBox, Tags } from '~/common/components';
import { cx } from '~/common/utils';
import { TeamsView } from '../../components';
import { OrderViewers } from './OrderViewers';
export const tableConfig = {
    columns: [
        {
            key: 'id',
            name: 'ID',
            width: '106px',
            getClassName: () => 'flex gap-[6px] items-start',
            cellRenderer: ({ cellData, rowData }) => (_jsxs("div", { className: "space-y-[10px]", children: [_jsxs("div", { className: "flex items-center gap-1", children: [_jsx("span", { children: cellData }), rowData.is_confidential && (_jsx(IconBox, { icon: faLock, className: "text-greyscale-500", "data-tt": "This order is restricted" }))] }), _jsx(OrderViewers, { id: rowData.id, size: "xs" })] })),
            noTruncate: true,
        },
        {
            key: 'user',
            name: 'Customer',
            width: 'minmax(100px, 1fr)',
            cellRenderer: ({ cellData, rowData }) => (_jsxs(_Fragment, { children: [_jsx("div", { "data-tt": cx(cellData.suspend_reason), className: "truncate", children: cellData.name }), _jsx("div", { className: "flex items-center absolute bottom-2 h-[18px] gap-[4px]", children: _jsx(Tags, { tags: rowData.tags, tagSize: "small", className: "max-w-[calc(100vw-250px)]" }) })] })),
            getClassName: ({ cellData }) => cx(cellData.suspended && 'text-danger-400', 'relative max-w-full'),
            noTruncate: true,
        },
        {
            key: 'notifications',
            name: '',
            cellRenderer: ({ cellData }) => !!cellData && (_jsxs("div", { "data-tt": cellData + ' new notifications', "data-tt-placement": "right", className: "relative h-2 w-2 top-0 z-[2]", children: [_jsx(IconBox, { size: "s", icon: faEnvelope, className: "text-greyscale-500" }), _jsx("div", { className: cx('absolute right-[-1px] top-[1px] h-[6px] w-[6px] rounded-full pointer-events-none', 'bg-primary-300 border border-solid border-greyscale-0') })] })),
            width: '40px',
        },
        {
            key: 'slides',
            name: 'Slides',
            width: '90px',
        },
        {
            key: 'status',
            name: 'Status',
            width: '162px',
        },
        {
            key: 'revisions',
            name: 'Revisions',
            width: '112px',
        },
        {
            key: 'price',
            name: 'Price',
            width: '80px',
        },
        {
            key: 'manager',
            name: 'PM',
            cellRenderer: ({ cellData }) => { var _a; return (_a = cellData === null || cellData === void 0 ? void 0 : cellData.name) !== null && _a !== void 0 ? _a : '—'; },
            width: 'minmax(144px, 1fr)',
        },
        {
            key: 'team',
            name: 'Design Team',
            cellRenderer: ({ cellData, rowData }) => cellData ? _jsx(TeamsView, { name: cellData.name, tag_teams: rowData.tag_teams }) : '—',
            width: 'minmax(144px, 1fr)',
        },
        {
            key: 'deadline',
            name: 'Deadline',
            cellRenderer: ({ cellData }) => _jsx(Deadline, { deadline: cellData }),
            width: '140px',
        },
    ],
    sortable: ['id', 'slides', 'revisions', 'price', 'deadline'],
    getCellClassName: () => 'h-[78px] pt-[14px]',
};
