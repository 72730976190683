import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { autoUpdate, FloatingNode, FloatingPortal, offset, size, useClick, useDismiss, useFloating, useFloatingNodeId, useFocus, useInteractions, useListNavigation, useTransitionStatus, } from '@floating-ui/react';
import { faChevronUp, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useMemo, useRef, useState, } from 'react';
import { IconBox, Input } from '~/common/components';
import { useEvent } from '~/common/hooks';
import { cx, noop } from '~/common/utils';
import fade from '~/styles/fade.module.scss';
import styles from './Select.module.scss';
const defaultItemRenderer = ({ option, inputValue, ...props }) => (_jsx("li", { ...props, children: _jsx("span", { className: "truncate", children: option.name }) }));
const defaultNotFoundRenderer = ({ className }) => (_jsx("li", { className: className, children: "Nothing found" }));
export const defaultOptionsFilter = (search) => (option) => option.name.toLowerCase().includes(search);
export const SelectFactory = ({ value, onChange, options, inputField, itemRenderer = defaultItemRenderer, notFoundRenderer = defaultNotFoundRenderer, onBlur: formOnBlur = noop, filterOptions = defaultOptionsFilter, noSearch = false, theme = 'light', containerClassName, placement = 'bottom-start', nullable, singleIcon, }) => {
    const [open, setOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    // kbd nav
    const [activeIndex, setActiveIndex] = useState(null);
    // kbd nav
    const listRef = useRef([]);
    const nodeId = useFloatingNodeId();
    const { optionName, selectedIndex } = useMemo(() => {
        var _a, _b;
        // kbd nav
        const index = options.findIndex((option) => option.value === value);
        const optionName = (_b = (_a = options[index]) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '';
        return { optionName, selectedIndex: index === -1 ? null : index };
    }, [options, value]);
    const inputValue = open && !noSearch ? searchText : optionName;
    const { context, refs, floatingStyles } = useFloating({
        nodeId,
        placement,
        open,
        onOpenChange: setOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            size({
                apply({ rects, elements }) {
                    Object.assign(elements.floating.style, {
                        width: `${rects.reference.width}px`,
                    });
                },
                padding: 10,
            }),
            offset({ mainAxis: 5 }),
        ],
    });
    const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
        useClick(context, { keyboardHandlers: false }),
        useFocus(context),
        useDismiss(context),
        // kbd nav
        useListNavigation(context, {
            listRef,
            activeIndex,
            selectedIndex,
            onNavigate: setActiveIndex,
            virtual: true,
            loop: true,
        }),
    ]);
    const { isMounted, status } = useTransitionStatus(context);
    const items = open && !noSearch ? options.filter(filterOptions(searchText.toLowerCase())) : options;
    // a hack to have updated form state when validating
    const freshOnBlur = useEvent(formOnBlur);
    const onBlur = (_event) => setTimeout(freshOnBlur, 100);
    const handleChange = (event) => {
        setSearchText(event.target.value);
        // kbd nav
        setActiveIndex(0);
        setOpen(true);
    };
    const handleFocus = () => {
        setSearchText('');
    };
    // kbd nav
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && activeIndex != null && items[activeIndex]) {
            onChange(items[activeIndex].value);
            onBlur(null);
            setOpen(false);
        }
    };
    const inputFieldProps = getReferenceProps({
        ref: refs.setReference,
        value: inputValue,
        onChange: handleChange,
        onFocus: handleFocus,
        onBlur,
        onKeyDown: handleKeyDown,
        readOnly: noSearch,
        children: (_jsxs(_Fragment, { children: [nullable && value !== null && (_jsx(IconBox, { size: "s", className: "cursor-pointer", icon: faTimes, onClick: (e) => {
                        e.preventDefault();
                        onChange(null);
                    } })), !singleIcon && !(nullable && value !== null) && (_jsx(IconBox, { size: "s", className: cx(styles.chevron, open && styles.chevronActive), icon: faChevronUp }))] })),
    });
    return (_jsxs(_Fragment, { children: [inputField(inputFieldProps), isMounted && (_jsx(FloatingNode, { id: nodeId, children: _jsx(FloatingPortal, { children: _jsx("div", { ...getFloatingProps({
                            ref: refs.setFloating,
                            className: cx(styles.container, containerClassName, fade.floating, {
                                [styles.dark]: theme === 'dark',
                                [styles.darken]: theme === 'darken',
                            }),
                            style: floatingStyles,
                        }), "data-status": status, children: _jsx("ul", { className: styles.options, children: items.length
                                ? items.map((option, index) => itemRenderer({
                                    option,
                                    inputValue: searchText,
                                    ...getItemProps({
                                        key: String(option.value),
                                        ref(node) {
                                            listRef.current[index] = node;
                                        },
                                        onClick(event) {
                                            onChange(option.value);
                                            onBlur(event);
                                            setOpen(false);
                                        },
                                        className: cx(styles.option, 'truncate', {
                                            [styles.optionActive]: activeIndex === index,
                                        }),
                                    }),
                                }))
                                : notFoundRenderer({ className: styles.notFound }) }) }) }) }))] }));
};
export const Select = ({ value, onChange, onBlur, options, noSearch, placement, itemRenderer, notFoundRenderer, containerClassName, nullable = false, singleIcon, ...props }) => (_jsx(SelectFactory, { value: value, onChange: onChange, onBlur: onBlur, options: options, noSearch: noSearch, placement: placement, theme: props.theme, filterOptions: props.filterOptions, containerClassName: containerClassName, inputField: (inputProps) => _jsx(Input, { ...props, ...inputProps }), itemRenderer: itemRenderer, notFoundRenderer: notFoundRenderer, 
    // TODO lol
    nullable: nullable, singleIcon: singleIcon }));
