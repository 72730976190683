import { useMutation } from '@tanstack/react-query';
import { httpClient } from '~/common/utils';
export const useUpdateTags = ({ taggable_type, taggable_id, onMutationSuccess, }) => {
    return useMutation({
        mutationFn: (values) => {
            const data = {
                taggable_type,
                taggable_id,
                values,
            };
            return httpClient.post('v1/staff/tags/update', { data });
        },
        onSuccess: onMutationSuccess,
    });
};
