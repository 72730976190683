import { useMemo } from 'react';
import { record } from '~/common/utils';
import { getSort } from '../utils';
import { useParsedQuery } from './useParsedQuery';
const ROWS_PER_PAGE = 15;
const compare = (a, b) => {
    return a.localeCompare(b, undefined, {
        numeric: true,
        sensitivity: 'base',
    });
};
// I know this looks like a garbage, but it works
const sortRows = (displayItems, sort, cellValueGetters) => {
    const { option, order } = sort;
    return displayItems.slice().sort((a, b) => {
        var _a, _b, _c, _d;
        const aValue = ((_b = (_a = cellValueGetters[option]) === null || _a === void 0 ? void 0 : _a.call(cellValueGetters, a[option])) !== null && _b !== void 0 ? _b : a[option]);
        const bValue = ((_d = (_c = cellValueGetters[option]) === null || _c === void 0 ? void 0 : _c.call(cellValueGetters, b[option])) !== null && _d !== void 0 ? _d : b[option]);
        if (typeof aValue === 'string' && typeof bValue === 'string') {
            return order === 'asc' ? compare(aValue, bValue) : compare(bValue, aValue);
        }
        if (typeof aValue === 'number' && typeof bValue === 'number') {
            return order === 'asc' ? aValue - bValue : bValue - aValue;
        }
        if (typeof aValue === 'string' && bValue == null) {
            return order === 'asc' ? -1 : 1;
        }
        if (typeof bValue === 'string' && aValue == null) {
            return order === 'asc' ? 1 : -1;
        }
        if (typeof aValue === 'number' && bValue == null) {
            return order === 'asc' ? -1 : 1;
        }
        if (typeof bValue === 'number' && aValue == null) {
            return order === 'asc' ? 1 : -1;
        }
        if (aValue == null && bValue == null) {
            return 0;
        }
        throw new Error("Couldn't sort these values, consider adding simple value getters for objects " +
            JSON.stringify({ aValue, bValue, option, order }));
    });
};
//TODO Fix return types
export const useFrontendTable = ({ data, tableConfig, limit = ROWS_PER_PAGE, getFilterEntries = (options) => options, }) => {
    var _a, _b, _c;
    const { queryParams, setQueryParams } = useParsedQuery();
    // TODO fix type, or whatever
    const onSort = (option) => {
        setQueryParams((params) => {
            var _a;
            return ({
                ...params,
                sort: getSort(((_a = params.sort) === null || _a === void 0 ? void 0 : _a.option) === option ? params.sort : option),
            });
        });
    };
    const onPageChange = (page) => {
        setQueryParams((params) => ({ ...params, page }));
    };
    const searchCache = useMemo(() => {
        if (!data) {
            return null;
        }
        return data.reduce((items, item) => {
            items[item.id] = tableConfig.searchable
                .map((key) => item[key])
                .join(' ')
                .toLowerCase();
            return items;
        }, {});
    }, [data, tableConfig.searchable]);
    const cellValueGetters = useMemo(() => {
        return tableConfig.columns.reduce((getters, column) => {
            if (column.getCellValue) {
                getters[column.key] = column.getCellValue;
            }
            return getters;
        }, {});
    }, [tableConfig.columns]);
    if (!data || !searchCache) {
        return null;
    }
    let displayItems = data.slice();
    // TODO since we filter using filter field, it makes sense to add filterable
    // fields to tableConfig, maybe exactFilters (case for selects), so we have proper types here
    const filterEntries = getFilterEntries(record.entries(queryParams.filter).filter(([key]) => key !== 'search'));
    if (filterEntries.length) {
        displayItems = displayItems.filter((item) => {
            for (const [stringKey, filterValue] of filterEntries) {
                const key = stringKey;
                if (!(key in item))
                    continue;
                const value = filterValue;
                const itemValue = (cellValueGetters[key] ? cellValueGetters[key](item[key]) : item[key]);
                if (!itemValue)
                    return false;
                if ((typeof value === 'boolean' && Boolean(itemValue) === value) ||
                    (Array.isArray(value) && !Array.isArray(itemValue) && value.includes(itemValue)) ||
                    (Array.isArray(value) &&
                        Array.isArray(itemValue) &&
                        value.some((key) => itemValue.some((itemKey) => itemKey.toString() === key.toString()))) ||
                    (typeof value === 'string' &&
                        value.toLowerCase().includes(itemValue.toString().toLowerCase())) ||
                    (typeof value === 'number' && value === itemValue)) {
                    continue;
                }
                return false;
            }
            return true;
        });
    }
    const search = queryParams.filter.search;
    if (search) {
        const lowerCasedSearch = search.toLowerCase();
        displayItems = displayItems.filter((item) => {
            return searchCache[item.id].includes(lowerCasedSearch);
        });
    }
    if (queryParams.sort && displayItems.length) {
        displayItems = sortRows(displayItems, queryParams.sort, cellValueGetters);
    }
    const page = (_a = queryParams.page) !== null && _a !== void 0 ? _a : 1;
    const indexOfLastRow = page * limit;
    const indexOfFirstRow = indexOfLastRow - limit;
    const currentRows = displayItems.slice(indexOfFirstRow, indexOfLastRow);
    return {
        tableConfig,
        items: currentRows,
        totalPages: Math.ceil(displayItems.length / limit),
        page: (_b = queryParams.page) !== null && _b !== void 0 ? _b : 1,
        onPageChange,
        sort: (_c = queryParams.sort) !== null && _c !== void 0 ? _c : null,
        onSort,
    };
};
