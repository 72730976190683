import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ParseLinks } from '~/common/components';
import { formatDate } from '~/common/utils';
import { useOrderData } from '~/orders/hooks';
import { SubSection } from '../Section';
const DetailsItem = ({ title, value }) => {
    return (_jsxs("div", { children: [_jsx("div", { className: "font-brand-c1r text-greyscale-500", children: title }), _jsx("div", { className: "font-brand-b1 text-greyscale-600", children: value || '—' })] }));
};
export const Objectives = () => {
    const { brief } = useOrderData();
    return (_jsx(SubSection, { title: "Presentation objectives", children: _jsxs("div", { className: "grid grid-cols-3 gap-x-5 gap-y-2 px-1", children: [_jsx(DetailsItem, { title: "Purpose", value: brief.purpose }), _jsx(DetailsItem, { title: "Deliverable", value: brief.deliverable }), _jsx(DetailsItem, { title: "Create a new template", value: brief.should_create_template }), _jsx(DetailsItem, { title: "Presentation schedule", value: brief.scheduled_at ? formatDate(brief.scheduled_at) : null }), _jsx(DetailsItem, { title: "Specific preferences", value: brief.diversity ? _jsx(ParseLinks, { children: brief.diversity }) : null }), _jsx(DetailsItem, { title: "ADS Scope", value: brief.scope })] }) }));
};
