import { z } from 'zod';
import { DateFromIsoString, Link, nonNullable } from '~/common/utils';
import { getListDecoder, NumericIdNamePair, StringIdNamePair, Tag } from '~/root';
export const TransactionsInit = z.object({
    payment_methods: z.array(NumericIdNamePair),
    products: z.array(StringIdNamePair),
    statuses: z.array(Tag),
    users: z.array(NumericIdNamePair),
});
export const TransactionsListItem = (statuses) => {
    return z.object({
        id: z.number(),
        product: z.string().nullable(),
        description: z.string().nullable(),
        payment_method: NumericIdNamePair,
        status: z
            .number()
            .transform((id) => nonNullable(statuses.find((status) => status.value === id))),
        amount: z.string(),
        credit_balance: z.string().nullable(),
        invoice_url: Link.nullable(),
        invoice_number: z.string().nullable(),
        created_at: DateFromIsoString,
        user: z.string().nullable(),
    });
};
export const TransactionsList = (statuses) => {
    return getListDecoder(TransactionsListItem(statuses));
};
