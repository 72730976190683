import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Avatar, Button, FileInput, FileUploadField, ModalBody, ModalCore, ModalFooter, ModalHeader, Toast, } from '~/common/components';
import { Crop } from '~/common/kits/crop';
import { getColorsFromNumber, getDimensionsFromFile, getInitials, isFileBroken, } from '~/common/utils';
import { useAvatarUploadMutation } from '../hooks';
import { avatarMimeTypes } from './constants';
export const UserAvatar = ({ user, avatar, onFileUpload, removeProfileAvatar }) => {
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);
    const { mutateAsync, isLoading } = useAvatarUploadMutation();
    const onFileSelect = async (file) => {
        const isSelectedFileBroken = await isFileBroken(file);
        const isImage = ['jpg', 'jpeg', 'png'].some((type) => file.type.includes(type));
        const size = isImage ? await getDimensionsFromFile(file) : null;
        const isValidSize = size ? size.width >= 360 && size.height >= 360 : false;
        if (!isSelectedFileBroken && isImage && isValidSize) {
            setError(null);
            setFile(file);
            return;
        }
        const error = 'Selected file does not meet an avatar requirements';
        setError(error);
        Toast.error({ title: 'Error', subTitle: error });
    };
    const resetNewAvatarSelect = () => setFile(null);
    const upload = (file) => {
        mutateAsync(file, {
            onError: () => {
                Toast.error({ title: 'Error', subTitle: 'Upload unsuccessful. Try pressing save again.' });
            },
            onSuccess: (uploadedFile) => {
                onFileUpload(uploadedFile);
                setFile(null);
            },
        });
    };
    return (_jsxs(_Fragment, { children: [avatar ? (_jsxs("div", { className: "flex", children: [_jsx(Avatar, { size: "big", colorSet: getColorsFromNumber(user.id), url: avatar.link, className: "mr-[45px]", children: getInitials(user.name) }), _jsxs("div", { className: "flex gap-2 items-center h-fit", children: [_jsx(FileInput, { labelClassName: "hover:cursor-pointer", accept: avatarMimeTypes, multiple: false, onChoose: (files) => onFileSelect(files[0]), children: _jsx(Button.Div, { color: "grey", children: "Change" }) }), _jsx(Button, { color: "text-grey", size: "xs", onClick: removeProfileAvatar, children: "Remove" })] })] })) : (_jsx(FileUploadField, { onChange: (file) => file && onFileSelect(file), errors: error ? [error] : null, hintLeft: "Supports: PNG, JPG", hintRight: "Minimum size: 360x360", accept: ".png, .jpg, .jpeg" })), _jsxs(ModalCore, { opened: !!file, onClose: () => setFile(null), className: "w-[440px]", children: [_jsx(ModalHeader, { title: "Edit your photo", onClose: resetNewAvatarSelect }), file && (_jsx(Crop, { file: file, onResize: upload, contentRenderer: (props) => (_jsxs(_Fragment, { children: [_jsx(ModalBody, { children: props.children }), _jsxs(ModalFooter, { className: "flex justify-between", children: [_jsx(FileInput, { accept: avatarMimeTypes, wrapperClassName: "flex items-center ml-1", labelClassName: "hover:cursor-pointer", multiple: false, onChoose: (files) => onFileSelect(files[0]), children: _jsx(Button.Div, { size: "xs", color: "text-secondary", children: "Upload new picture" }) }), _jsxs("div", { className: "flex gap-3", children: [_jsx(Button, { color: "grey", onClick: resetNewAvatarSelect, disabled: isLoading, children: "Cancel" }), _jsx(Button, { color: "primary", onClick: () => {
                                                        props
                                                            .handleSave()
                                                            .catch(() => setError('Something went wrong when resizing'));
                                                    }, loading: isLoading, children: "Save" })] })] })] })) }))] })] }));
};
