import { z } from 'zod';
import { DateFromIsoString, Email, Link, TimelessDateFromIsoString, UUID } from '~/common/utils';
const getPermissions = (list) => {
    const permissionSet = new Set(list);
    const h = (permission) => permissionSet.has(permission);
    return {
        customersBillingUpdate: h('customers.billing.update'),
        customersCanLoginAsCustomer: h('customers.login-as-customer'),
        customersDelete: h('customers.delete'),
        customersMembersDelete: h('customers.members.delete'),
        customersMembersInvite: h('customers.members.invite'),
        customersMembersUpdate: h('customers.members.update'),
        customersMembersView: h('customers.members.view'),
        customersTransactionsCreate: h('customers.transactions.create'),
        customersTransactionsView: h('customers.transactions.view'),
        customersUpdate: h('customers.update'),
        customersView: h('customers.view'),
        designTeamsCreate: h('design-teams.create'),
        designTeamsDelete: h('design-teams.delete'),
        designTeamsUpdate: h('design-teams.update'),
        designTeamsView: h('design-teams.view'),
        officesManage: h('offices.manage'),
        officesView: h('offices.view'),
        ordersChat: h('orders.chat'),
        ordersDeadlineUpdate: h('orders.deadline.update'),
        ordersDraftCreate: h('orders.draft.create'),
        ordersPricingUpdate: h('orders.pricing.update'),
        ordersTeamsUpdate: h('orders.teams.update'),
        ordersUpdate: h('orders.update'),
        ordersBriefUpdate: h('orders.brief.update'),
        ordersView: h('orders.view'),
        rmsUpdate: h('rms.update'),
        rmsView: h('rms.view'),
        shiftsUpdate: h('shifts.update'),
        shiftsView: h('shifts.view'),
        subscriptionsCancel: h('subscriptions.cancel'),
        subscriptionsCreate: h('subscriptions.create'),
        subscriptionsUpdate: h('subscriptions.update'),
        subscriptionsView: h('subscriptions.view'),
        usersCreate: h('users.create'),
        usersDelete: h('users.delete'),
        usersPermissionsUpdate: h('users.permissions.update'),
        usersPermissionsView: h('users.permissions.view'),
        usersUpdate: h('users.update'),
        usersView: h('users.view'),
        organizationsView: h('organisations.view'),
        organizationsCreate: h('organisations.create'),
        organizationsUpdate: h('organisations.update'),
        holidaysView: h('holidays.view'),
        holidaysManage: h('holidays.manage'),
        settingsCapacity: h('settings.capacity'),
        devToolsJobQueue: h('dev_tools.queue'),
    };
};
export const Role = z.enum([
    'admin',
    'customer_supporter',
    'design_manager',
    'designer',
    'regular_pm',
    'sales_manager',
    'subscription_pm',
    'other',
]);
export const UploadedFileId = z.number().int().positive().or(UUID);
export const IdNamePair = z.object({
    id: z.union([z.number(), z.string()]),
    name: z.string(),
});
export const NumericIdNamePair = z
    .object({
    id: z.number(),
    name: z.string(),
})
    .transform(({ id, name }) => ({ value: id, name }));
export const StringIdNamePair = z
    .object({
    id: z.string(),
    name: z.string(),
})
    .transform(({ id, name }) => ({ value: id, name }));
export const StringValueNamePair = z.object({
    value: z.string(),
    name: z.string(),
});
export const Country = z
    .object({
    // TODO figure out how to merge objects after transform
    id: z.number(),
    name: z.string(),
    country_code: z.string(),
    dial_code: z.number(),
    continent_id: z.string(),
    original_name: z.string(),
})
    .transform(({ id, ...rest }) => ({ ...rest, value: id }));
// TODO end
export const Office = z.object({
    id: z.number(),
    country: z.string(),
    country_code: z.string(),
    city: z.string(),
});
export const UploadedFile = z
    .object({
    id: UploadedFileId,
    name: z.string(),
    link: Link,
    date: DateFromIsoString,
    timestamp: DateFromIsoString.optional(),
})
    .transform(({ date, timestamp, ...file }) => ({
    ...file,
    date: date || timestamp,
}));
export const User = z
    .object({
    id: z.number().int().positive(),
    name: z.string(),
    avatar: UploadedFile.nullable(),
    email: Email,
    permissions: z.array(z.string()),
    role: Role,
    team: z
        .object({
        id: z.number(),
        name: z.string(),
    })
        .nullable(),
    temp_manager: z.boolean(),
    office: Office,
    created_at: TimelessDateFromIsoString,
})
    .transform(({ permissions, ...currentUser }) => ({
    ...currentUser,
    permissions: getPermissions(permissions),
}));
export const CurrentUser = z
    .object({
    user: User,
    links: z.object({
        legacy_admin_panel: Link,
    }),
    token: z.string(),
})
    .transform(({ user, links, token }) => ({
    ...user,
    token,
    uniqueId: Math.random(),
    legacyAdmin: links.legacy_admin_panel,
}));
export const BadgeColor = z.enum([
    'accent',
    'accent-red',
    'danger',
    'danger-solid',
    'grey',
    'lighter-grey',
    'grey-solid',
    'info',
    'primary',
    'secondary',
    'secondary-solid',
    'success',
    'warning',
    'accent-salad-green',
]);
export const Tag = z
    .object({
    // Sadly this might not be unique in some circumstances
    id: z.union([z.string(), z.number()]),
    name: z.string(),
    color: BadgeColor,
    hint: z.string().optional(),
})
    .transform(({ id, ...props }) => ({
    value: id,
    ...props,
}));
export const CustomerLastActiveUpdate = z.object({
    user_id: z.number(),
    last_active_at: DateFromIsoString,
});
export const Metadata = z.object({
    hasMore: z.boolean(),
    pages: z.number().int().nonnegative(),
    results: z.number().int().nonnegative(),
    total: z.number().int().nonnegative(),
});
const Cursor = z
    .number()
    .int()
    .nonnegative()
    .nullable()
    .transform((cursor) => cursor !== null && cursor !== void 0 ? cursor : undefined);
export const InfiniteMetadata = z.object({ next: Cursor, prev: Cursor });
export const OfficeForSelect = Office.transform(({ id, city, country, country_code }) => ({
    name: `${country}, ${city}`,
    value: id,
    country,
    country_code,
}));
export const getListDecoder = (Item) => z.object({
    items: z.array(Item),
    metadata: Metadata,
});
export const Shift = z.object({
    id: z.number().int().positive(),
    sign: z.string(),
    range: z.string(),
});
export const LoginResponse = z.object({ token: z.string() });
export const PhoneNumber = z.object({
    country_id: z.number().nullable(),
    number: z.string(),
});
export const CardBrand = z.string();
export const BountyType = z.enum(['absolute', 'percent']);
export const Bounty = z.object({
    value: z.number().nonnegative(),
    type: BountyType,
});
export const Currency = z.enum(['USD', 'EUR', 'GBP', 'DKK']);
const SharedStyleData = z.object({
    id: z.number(),
    name: z.string(),
});
export const CustomPortalStyle = SharedStyleData.extend({
    preview: Link,
    file: Link.nullable(),
    is_enabled: z.boolean(),
}).transform(({ file, preview, ...style }) => ({
    ...style,
    file_url: file,
    preview_url: preview,
    created_at: undefined,
    disabled: !style.is_enabled,
}));
export const DefaultStyle = SharedStyleData;
export const CustomStyle = SharedStyleData.extend({
    description: z.string(),
    preview: UploadedFile,
    file: UploadedFile,
}).transform(({ file, preview, ...style }) => ({
    ...style,
    file_url: file.link,
    preview_url: preview.link,
    disabled: false,
    created_at: file.date,
}));
export const Timezone = z
    .object({
    id: z.number(),
    timezone_name: z.string(),
    description: z.string(),
    UTC: z.string(),
})
    .transform(({ UTC, description, id, timezone_name }) => ({
    value: id,
    name: `(${UTC}) ${description}`,
    id,
    description,
    shortName: UTC,
    timezone_name,
}));
export const Timezones = z.array(Timezone);
