import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faBars, faInfoCircle, faList } from '@fortawesome/pro-regular-svg-icons';
import { faGrid2 } from '@fortawesome/pro-solid-svg-icons';
import { Badge, Button, FilterSwitcher, FiltersButton, IconBox, LayoutHeader, LayoutTitle, ListCount, Popover, SegmentedButton, } from '~/common/components';
import { CategoryMultiselect, Filter, getFilterProps, getSearchFilterDataFromOptions, Multiselect, Search, useParsedQuery, } from '~/common/kits/table';
import { useInitData } from '~/orders/hooks';
import { FilterBar } from './FilterBar';
import { mergedTagFilters, useOmittedFilters, useOrdersTabs } from './hooks';
import { Legend } from './Legend';
const searchFilters = ['customer', 'company', 'order'];
const placeholderMapper = (option) => {
    switch (option) {
        case 'customer':
            return 'Enter email';
        case 'company':
            return 'Enter company name';
        case 'order':
            return 'Enter ID or title';
    }
};
const Item = ({ value, color, name, hint }) => (_jsxs("div", { className: "flex items-center justify-between w-full pr-[4px]", children: [_jsx(Badge, { color: color, children: name }), hint && (_jsx(IconBox, { "data-tt": hint, size: "s", className: "pointer-events-auto", icon: faInfoCircle }))] }, value));
export const Header = ({ ordersCount }) => {
    const { activeTab, setActiveTab } = useOrdersTabs();
    const { filter_options } = useInitData();
    const omittedFilters = useOmittedFilters();
    const { queryParams, onFilterChange, updateFilters } = useParsedQuery();
    const searchFilterData = getSearchFilterDataFromOptions(searchFilters, queryParams.filter);
    const filterProps = getFilterProps(filter_options, queryParams.filter, onFilterChange);
    // For N merged filters counter should be +1, not +N
    const getAppliedFiltersAmount = () => {
        const appliedQueryFilters = Object.keys(queryParams.filter);
        const omittedFiltersByDefault = appliedQueryFilters.filter((key) => !omittedFilters.includes(key) &&
            !searchFilters.includes(key));
        const omittedMergeFilters = omittedFiltersByDefault.filter((key) => !mergedTagFilters.includes(key));
        return (omittedMergeFilters.length +
            Math.min(1, omittedFiltersByDefault.length - omittedMergeFilters.length));
    };
    const appliedFiltersAmount = getAppliedFiltersAmount();
    const resetFilters = () => updateFilters(() => ({}));
    return (_jsx(LayoutHeader, { children: _jsxs("div", { className: "flex flex-col gap-1", children: [_jsxs("div", { className: "flex justify-between items-center", children: [_jsxs("div", { className: "flex items-baseline", children: [_jsx(LayoutTitle, { children: "Orders" }), ordersCount !== undefined && activeTab === 'list' && (_jsx(ListCount, { name: "order", className: "font-brand-b1 text-greyscale-400 ml-2", amount: ordersCount }))] }), _jsxs("div", { className: "ml-auto flex", children: [activeTab === 'list' && (_jsx(Search, { className: "mr-3", filters: searchFilters, selectedFilter: searchFilterData.selectedFilter, value: searchFilterData.value, onChange: onFilterChange, updateFilters: updateFilters, placeholderMapper: placeholderMapper })), _jsx(Popover, { compensateOffset: 8, trigger: (props) => (_jsx(Button, { color: "grey", icon: faList, ...props, children: "Legend" })), placement: "top-end", className: "w-[425px] font-brand-b2r py-[12px] px-2", content: () => _jsx(Legend, {}) }), _jsx(Popover, { compensateOffset: 8, className: "space-y-2 p-2 pt-[12px] w-[275px]", placement: "bottom-end", trigger: (props) => (_jsx(FiltersButton, { ...props, appliedFiltersAmount: appliedFiltersAmount })), content: () => (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex items-center justify-between mb-[-4px]", children: [_jsx("h3", { className: "font-brand-t2m text-greyscale-600", children: "Filters" }), appliedFiltersAmount > 0 && (_jsx(Button, { color: "text-secondary", size: "xs", onClick: resetFilters, children: "Reset" }))] }), _jsx(Multiselect, { placeholder: "Order status", ...filterProps('status') }), _jsx(CategoryMultiselect, { reset: () => updateFilters((prev) => ({
                                                    ...prev,
                                                    ...Object.fromEntries(mergedTagFilters.map((filterName) => [filterName, undefined])),
                                                })), categories: [
                                                    {
                                                        label: 'Order tag',
                                                        list: [
                                                            {
                                                                ...filterProps('order_system_tags'),
                                                                itemRenderer: (props) => _jsx(Item, { ...props }),
                                                            },
                                                            {
                                                                ...filterProps('order_custom_tags'),
                                                                itemRenderer: (props) => _jsx(Item, { ...props }),
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        label: 'Customer tag',
                                                        list: [
                                                            {
                                                                ...filterProps('customer_system_tags'),
                                                                itemRenderer: (props) => _jsx(Item, { ...props }),
                                                            },
                                                            {
                                                                ...filterProps('customer_custom_tags'),
                                                                itemRenderer: (props) => _jsx(Item, { ...props }),
                                                            },
                                                        ],
                                                    },
                                                ], placeholder: "Tag type" }), _jsx(Multiselect, { placeholder: "Customer type", ...filterProps('customer_type') }), _jsx(Multiselect, { placeholder: "PM", ...filterProps('manager') }), _jsx(Multiselect, { placeholder: "Design team", ...filterProps('team') }), _jsx(Multiselect, { placeholder: "Payment status", ...filterProps('payment_status') }), _jsx(Multiselect, { placeholder: "Custom portal", ...filterProps('custom_portal') }), _jsx(Multiselect, { placeholder: "Organization", ...filterProps('organisation') }), activeTab !== 'board' && (_jsx(Filter, { ...filterProps('completed'), placeholder: "Completed", theme: "light" })), _jsx("hr", { className: "h-[1px] w-full text-greyscale-200" }), _jsx(FilterSwitcher, { label: "New notifications", className: "min-w-fit max-w-fit", value: queryParams.filter.notifications, onChange: (value) => onFilterChange('notifications', value) })] })) }), _jsx(SegmentedButton, { className: "ml-2", value: activeTab, setValue: setActiveTab, options: [
                                        { icon: faBars, value: 'list' },
                                        { icon: faGrid2, value: 'board' },
                                    ] })] })] }), _jsx(FilterBar, {})] }) }));
};
