import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Tags } from '~/common/components';
import { formatDayMonthYear } from '~/common/utils';
export const tableConfig = {
    columns: [
        { key: 'id', name: 'ID', width: '96px' },
        {
            key: 'name',
            name: 'Name',
            width: 'minmax(144px, 2fr)',
            cellRenderer: ({ cellData, rowData }) => (_jsxs(_Fragment, { children: [_jsx("div", { className: "truncate", children: cellData }), _jsx("div", { className: "flex items-center absolute bottom-2 h-[18px] gap-[4px]", children: _jsx(Tags, { tags: rowData.tags, tagSize: "small", className: "max-w-[calc(100vw-250px)]" }) })] })),
            getClassName: () => 'relative max-w-full',
            noTruncate: true,
        },
        {
            key: 'email',
            name: 'Email',
            width: 'minmax(144px, 2fr)',
        },
        {
            key: 'company',
            name: 'Company',
            width: 'minmax(144px, 1fr)',
        },
        {
            key: 'role',
            name: 'Role',
            width: 'minmax(100px, 1fr)',
        },
        {
            key: 'signed_up',
            name: 'Signed up',
            width: 'minmax(120px, 1fr)',
            cellRenderer: ({ cellData }) => formatDayMonthYear(cellData),
        },
        {
            key: 'last_update',
            name: 'Last update',
            width: 'minmax(120px, 1fr)',
            cellRenderer: ({ cellData }) => (cellData ? formatDayMonthYear(cellData) : '—'),
        },
    ],
    sortable: ['id', 'signed_up', 'last_update'],
    getCellClassName: () => 'h-[78px] pt-[14px]',
};
