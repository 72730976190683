import { jsx as _jsx } from "react/jsx-runtime";
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { forwardRef } from 'react';
import { cx } from '~/common/utils';
import { IconBox } from '../IconContainers';
import { Button } from '../Interactives';
import styles from './Badge.module.scss';
export const badgeIcons = {
    star: faStar,
};
export const Badge = forwardRef(({ className, color = 'grey', children, size = 'medium', ...props }, ref) => {
    const icon = (typeof children === 'string' &&
        badgeIcons[children.toLowerCase()]) ||
        null;
    return (_jsx("div", { ref: ref, ...props, className: cx(className, styles.badge, styles[color], styles[size], icon && 'aspect-square'), children: icon ? _jsx(IconBox, { icon: icon, className: "text-inherit !text-[10px]" }) : children }));
});
const BadgeButton = forwardRef(({ className, color = 'grey', children, size = 'medium', ...props }, ref) => (_jsx(Button.Base, { ...props, ref: ref, className: cx(className, styles.badge, styles[color], styles[size], 'focus-visible:ring'), children: children })));
Badge.Button = BadgeButton;
