import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Badge, Tooltip } from '~/common/components';
import { useVisibleElements } from '~/common/hooks';
import { cx, lastItem } from '~/common/utils';
const OBSERVER_OPTIONS = {
    rootMargin: '0px',
    threshold: 1,
};
export const Tags = ({ tags, className, tagSize = 'medium' }) => {
    const [{ visibleAmount, triggerOffset }, setLabelState] = useState({
        visibleAmount: 0,
        triggerOffset: 0,
    });
    const containerRef = useVisibleElements({
        onVisibleChange: (visibleElements) => {
            const lastVisible = lastItem(visibleElements);
            if (!lastVisible) {
                setLabelState({ visibleAmount: 0, triggerOffset: 0 });
                return;
            }
            if (lastVisible.rootBounds) {
                const containerOffsetLeft = lastVisible.rootBounds.left;
                const lastVisibleOffsetRight = lastVisible.boundingClientRect.right;
                setLabelState({
                    visibleAmount: visibleElements.length,
                    triggerOffset: lastVisibleOffsetRight - containerOffsetLeft,
                });
            }
        },
        observerOptions: OBSERVER_OPTIONS,
    }, [tags]);
    const amountOfHiddenTags = tags.length - visibleAmount;
    const hiddenTags = amountOfHiddenTags > 0 ? tags.slice(visibleAmount) : undefined;
    return (_jsxs("div", { className: cx(className, 'relative', {
            'pr-5': amountOfHiddenTags > 0,
        }), children: [_jsx("div", { ref: containerRef, className: cx('flex items-center overflow-hidden', tagSize === 'small' ? 'gap-[4px]' : 'gap-1'), children: tags.map(({ name, value, color }, index) => (_jsx(Badge, { color: color, size: tagSize, className: cx('flex-none opacity-0', {
                        'opacity-100': index < visibleAmount,
                    }), children: name }, value))) }), amountOfHiddenTags > 0 && (_jsx(Tooltip, { placement: "bottom", content: amountOfHiddenTags > 0 && (_jsx("div", { className: cx('flex max-w-[340px] flex-wrap', tagSize === 'small' ? 'gap-[4px]' : 'gap-1 p-2'), children: hiddenTags === null || hiddenTags === void 0 ? void 0 : hiddenTags.map(({ color, value, name }) => (_jsx(Badge, { color: color, size: tagSize, className: "shrink-0", children: name }, value))) })), children: _jsxs(Badge, { style: { left: triggerOffset }, className: cx('flex-none absolute top-0', visibleAmount > 0 && tagSize === 'small' ? 'ml-[4px]' : 'ml-1'), color: "grey", size: tagSize, children: ["+", amountOfHiddenTags] }) }))] }));
};
