import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faSlack, faTrello } from '@fortawesome/free-brands-svg-icons';
import { faBuilding, faChartPieAlt, faCommentAltLines, faFileImage, faTimes, } from '@fortawesome/pro-regular-svg-icons';
import { AvatarPane, Button, IconButton, ImageLoader, InfoBar, ModalCore, Tooltip, useModalContext, } from '~/common/components';
import { useModalProps } from '~/common/hooks';
import { formatDateShort, getColorsFromNumber } from '~/common/utils';
import { useCurrentUserData } from '~/root';
import { useTeamData } from '../hooks';
import { EditTeam } from './EditTeam';
export const TeamInfo = () => {
    var _a, _b, _c;
    const team = useTeamData();
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    const photoModal = useModalProps();
    const link = (_b = (_a = team.avatar) === null || _a === void 0 ? void 0 : _a.link) !== null && _b !== void 0 ? _b : '';
    const colorSet = getColorsFromNumber(team.id);
    return (_jsxs(_Fragment, { children: [_jsx(AvatarPane, { name: team.name, avatarLink: (_c = team.avatar) === null || _c === void 0 ? void 0 : _c.link, colorSet: colorSet, children: permissions.designTeamsUpdate && (_jsx(Button, { onClick: modalOpener(EditTeam), color: "grey", children: "Edit" })) }), _jsxs("div", { className: "px-4", children: [_jsx("div", { className: "font-brand-t1m text-greyscale-600", children: team.name }), _jsxs("div", { className: "font-brand-b2r text-greyscale-500 mt-1", children: ["Last update: ", formatDateShort(team.updated)] }), _jsxs("ul", { className: "mt-2", children: [_jsx(InfoBar, { truncate: true, icon: faCommentAltLines, text: team.description, hint: "Comment" }), _jsx(InfoBar, { icon: faBuilding, text: `${team.office.country} Office`, hint: "Office" }), _jsx(InfoBar, { icon: faChartPieAlt, text: `${team.daily_capacity} points`, hint: "Daily capacity points" }), _jsx(InfoBar, { truncate: true, icon: faSlack, text: team.slack_channel, hint: "Slack channel" }), _jsx(InfoBar, { truncate: true, icon: faTrello, text: team.trello_board_id, hint: "Trello board ID" }), _jsx(InfoBar, { className: "cursor-pointer", onClick: link ? photoModal.open : undefined, icon: faFileImage, text: _jsx(Tooltip, { className: "w-30 p-0", placement: "right", noArrow: true, content: link && _jsx(ImageLoader, { className: "rounded w-full", src: link }), children: _jsx("span", { children: "Team photo" }) }), hint: "Team photo" })] })] }), _jsxs(ModalCore, { className: "relative w-[588px] rounded-[16px]", ...photoModal, children: [_jsx(IconButton, { className: "absolute right-2 top-2 w-4 h-4 text-[32px] text-greyscale-500 focus-visible:ring", icon: faTimes, onClick: photoModal.onClose }), _jsx(ImageLoader, { className: "rounded-[16px] object-cover w-full", src: link })] })] }));
};
