import { useHistory, useLocation } from 'react-router-dom';
import { nonNullable } from '~/common/utils';
export const tabs = [
    { key: 'list', label: 'List' },
    { key: 'board', label: 'Board' },
];
const getActiveTab = (pathname) => {
    const lastElement = nonNullable(pathname.split('/').pop());
    return lastElement === 'orders' ? 'list' : lastElement;
};
export const useOrdersTabs = () => {
    const { pathname, search } = useLocation();
    const history = useHistory();
    return {
        activeTab: getActiveTab(pathname),
        setActiveTab: (tab) => {
            history.push({
                pathname: '/orders' + (tab === 'list' ? '' : `/${tab}`),
                search,
            });
        },
    };
};
export const useOmittedFilters = () => {
    const { activeTab } = useOrdersTabs();
    return (activeTab === 'board' ? ['completed', 'manager'] : []);
};
export const mergedTagFilters = [
    'customer_custom_tags',
    'customer_system_tags',
    'order_custom_tags',
    'order_system_tags',
];
