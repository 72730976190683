import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faMessageLines } from '@fortawesome/pro-solid-svg-icons';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, ModalHeader, ModalCore } from '~/common/components';
import { useOrderData } from '~/orders/hooks';
import { Checklist } from './Checklist';
import { InternalChat } from './InternalChat';
const TeamSpaceModal = ({ onClose }) => {
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { title: "Team space", onClose: onClose }), _jsxs("div", { className: "min-h-[595px] h-[calc(100vh-112px)] w-[1220px] flex px-2 gap-2", children: [_jsx(Checklist, {}), _jsx("div", { className: "w-[1px] bg-greyscale-200" }), _jsx(InternalChat, {})] })] }));
};
export const TeamSpace = () => {
    const { collaboration } = useOrderData();
    const history = useHistory();
    const { hash } = useLocation();
    const opened = hash.includes('team-space');
    const onClose = () => history.push({ hash: '' });
    const open = () => history.push({ hash: 'team-space' });
    return (_jsxs(_Fragment, { children: [_jsxs(Button, { onClick: open, color: "grey", icon: faMessageLines, children: [!!collaboration.unread_comments_count && (_jsx("div", { className: "absolute h-1 w-1 bg-danger-400 rounded-full left-3 bottom-2 pointer-events-none" })), _jsx("span", { children: "Team space" })] }), _jsx(ModalCore, { className: "w-fit", opened: opened, onClose: onClose, children: opened && _jsx(TeamSpaceModal, { onClose: onClose }) })] }));
};
