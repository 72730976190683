import { jsx as _jsx } from "react/jsx-runtime";
import { FloatingFocusManager, FloatingNode, FloatingOverlay, FloatingPortal, useClick, useDismiss, useFloating, useFloatingNodeId, useInteractions, useRole, useTransitionStatus, } from '@floating-ui/react';
import { cx, noop } from '~/common/utils';
import { outsidePress, withFloatingTree } from '../Floating';
import styles from './ModalCore.module.scss';
export const ModalCore = withFloatingTree(({ opened, children, onClose = noop, className }) => {
    const nodeId = useFloatingNodeId();
    const { refs, context } = useFloating({
        nodeId,
        open: opened,
        onOpenChange: (state) => {
            if (!state) {
                onClose();
            }
        },
    });
    const { getFloatingProps } = useInteractions([
        useClick(context),
        useRole(context),
        useDismiss(context, { outsidePress }),
    ]);
    const { isMounted, status } = useTransitionStatus(context);
    if (!isMounted) {
        return null;
    }
    return (_jsx(FloatingNode, { id: nodeId, children: _jsx(FloatingPortal, { children: _jsx(FloatingOverlay, { lockScroll: true, className: styles.backdrop, "data-status": status, children: _jsx(FloatingFocusManager, { context: context, children: _jsx("div", { ...getFloatingProps({
                            ref: refs.setFloating,
                            className: cx(styles.modal, className, 'focus-visible:outline-none'),
                        }), "data-status": status, onTransitionEnd: () => status === 'close' && onClose(), children: children }) }) }) }) }));
});
