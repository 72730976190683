import { jsx as _jsx } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef } from 'react';
import { cx } from '~/common/utils';
import { Button } from '../Interactives';
import css from './styles.module.scss';
const getProps = ({ className, icon, iconProps, size, ...props }) => ({
    ...props,
    className: cx('flex flex-none items-center justify-center', css.iconContainer, size && css[`iconSize-${size}`], className, {
        [css.disabled]: props.disabled,
    }),
    children: _jsx(FontAwesomeIcon, { ...iconProps, icon: icon }),
});
export const IconBox = forwardRef((props, ref) => (_jsx("div", { ref: ref, ...getProps(props) })));
export const IconButton = forwardRef((props, ref) => {
    const { className, ...buttonProps } = getProps(props);
    return _jsx(Button.Base, { ...buttonProps, ref: ref, className: cx(className, 'focus-visible:ring') });
});
