import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faStopwatch } from '@fortawesome/pro-regular-svg-icons';
import { differenceInHours } from 'date-fns';
import { cx } from '../utils';
import { DateDistance, IconBox } from './UI';
const HOURS_DIFF_CRITICAL = 3;
const HOURS_DIFF_WARNING = 24;
const getDeadlineClassName = (deadline) => {
    const diffHours = differenceInHours(deadline, new Date());
    if (diffHours <= HOURS_DIFF_CRITICAL) {
        return {
            text: 'text-danger-400',
            bg: 'bg-danger-50',
        };
    }
    if (diffHours <= HOURS_DIFF_WARNING) {
        return {
            text: 'text-warning-500',
            bg: 'bg-warning-50',
        };
    }
    return {
        text: 'text-greyscale-500',
        bg: 'bg-greyscale-100',
    };
};
export const Deadline = ({ deadline }) => {
    const deadlineClassName = getDeadlineClassName(deadline);
    return (_jsxs("div", { className: cx('flex items-center justify-center w-fit p-[2px] gap-[4px] rounded', deadlineClassName.bg), children: [_jsx(IconBox, { icon: faStopwatch, className: deadlineClassName.text }), _jsx(DateDistance, { to: deadline, className: cx(deadlineClassName.text, 'font-brand-c1m') })] }));
};
